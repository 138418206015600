<template lang="pug">
  div(class="create-contact")
    div(class="create-contact__header")
      h2(class="title-32px mb-8 create-contact__title") {{ $t(title) }}
    ValidationObserver(ref="contactFormObserver")
      v-form(@submit.prevent="onSubmit")
        div(class="form-grid")
          div(class="form-group")
            ValidationProvider(
              vid="name"
              v-slot="{ errors }"
              :name="wrapQuotesProviderName($t('PIB or name of organization'))"
              mode="lazy"
              rules="required|min:3"
            )
              v-text-field(
                filled
                :error-messages="errors"
                v-model.trim="form.name"
                :label="$t('PIB or name of organization')"
                :hide-details="xsDevice"
              )

          div(class="form-group")
            ValidationProvider(
              :rules="edrpouRules"
              vid="edrpou"
              v-slot="{ errors }"
              :name="wrapQuotesProviderName($t('EDRPOU'))"
              mode="lazy"
            )
              v-text-field(
                v-model.trim="form.edrpou"
                filled
                :error-messages="errors"
                :label="$t('EDRPOU')"
                :hide-details="xsDevice"
              )

          div(class="form-group")
            ValidationProvider(
              :rules="emailRules"
              vid="email"
              v-slot="{ errors }"
              :name="wrapQuotesProviderName($t('Email'))"
              mode="lazy"
            )
              v-text-field(
                v-model.trim="form.email"
                filled
                :error-messages="errors"
                :label="$t('Email')"
                :hide-details="xsDevice"
              )
        div(class="form-actions")
          v-btn(
            height="48"
            @click="closeModal"
          ) {{ $t('Cancel') }}
          v-btn(
            class="main-button"
            type="submit"
            :loading="formLoading"
          ) {{ $t('Create') }}
</template>

<script>
import { mapGetters } from 'vuex'
import EButtonMain from '~/components/elements/buttons/e-button-main'
import EInputWrapper from '~/components/elements/inputs/e-input-wrapper'
import EInputDynamic from '~/components/elements/inputs/e-input-dynamic'
import responsive from '~/mixins/pages/responsive'
import Contact from '~/modules/contacts/models/Contact'
import validate from '~/mixins/validation/validate'

export default {
  name: 'MCreateContact',
  components: {
    EButtonMain,
    EInputWrapper,
    EInputDynamic
  },
  mixins: [responsive, validate],
  props: {
    title: {
      type: String,
      default: ''
    },
    closeModal: {
      type: Function,
      default: () => {}
    },

    onSuccess: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        name: '',
        edrpou: '',
        email: ''
      },
      formLoading: false
    }
  },
  computed: {
    ...mapGetters('contacts', ['params']),
    edrpouRules() {
      if (this.form.email) return ''
      return 'required|numeric|min:8|max:10'
    },
    emailRules() {
      if (this.form.edrpou) return ''
      return 'required|email'
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.contactFormObserver.validate()
      if (!isValid) {
        return
      }

      const payload = {
        name: this.form.name,
        edrpou: this.form.edrpou || null,
        email: this.form.email || null
      }

      this.formLoading = true
      const sort = {}
      const sortName = this.params?.sortBy[0] ? `order[${this.params?.sortBy[0]}]` : ''
      if (sortName) {
        sort[sortName] = this.params?.sortDesc[0] ? 'desc' : 'asc'
      }
      try {
        await Contact.api().create(payload)
        await Contact.api()
          .filter({
            offset: this.params?.page,
            limit: this.params?.itemsPerPage,
            ...sort
          })
          .all()
        await this.closeModal()
        await this.onSuccess()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.formLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.create-contact__title {
  @media (max-width: map-get($breakpoints, 'sm')) {
    font-size: 24px !important;
    margin-bottom: 20px !important;
  }
}
.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  max-width: 644px;
  width: 100%;
  margin-bottom: 32px;
}

.form-group {
  display: flex;
  flex-direction: column;

  @media (max-width: map-get($breakpoints, 'sm')) {
    grid-column: span 2;
  }
}

.form-group:first-child {
  grid-column: span 2;
}

.form-actions {
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-top: auto;
  > button {
    flex: 1;
  }
}
</style>
