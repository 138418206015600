<template lang="pug">
  div(class="d-flex align-center pointer")
    e-svg-icon(v-bind="icon.attrs") {{ icon.name }}
    span(:class="textClass") {{ value }}
</template>

<script>
export default {
  props: {
    icon: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    },
    textClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss"></style>
