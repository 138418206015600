<template lang="pug">
  div(
    class="ghost"
    :style="style"
  )
    component(
      :is="component"
      v-bind="componentProps"
    )
</template>

<script>
import { mapGetters } from 'vuex'
import ETextWithIcon from '~/components/elements/text/e-text-with-icon'

export default {
  name: 'Ghost',
  components: {
    ETextWithIcon
  },
  computed: {
    ...mapGetters('ghosts', {
      ghost: 'ghost'
    }),
    component() {
      return this._.get(this.ghost, 'component', null)
    },
    componentProps() {
      return this._.get(this.ghost, 'componentProps', null)
    },
    style() {
      return this._.get(this.ghost, 'style', null)
    }
  }
}
</script>

<style scoped lang="scss">
.ghost {
  position: absolute;
  top: -9999;
  opacity: 0.8;
}
</style>
