<template lang="pug">
  div(class="create-contact-status")
    v-img(
      :src="require('~/assets/images/contact-status.png')"
      max-width="72"
    )
    h2(class="title-32px mb-2 create-contact-status__title") {{ title }}
    div(class="tmb-2 create-contact-status__description") {{ description }}
    div(class="create-contact-status__content")
      v-btn(
        class="main-button"
        @click="closeModal"
      ) {{ $t('Ok') }}
</template>

<script>
export default {
  name: 'MCreateContactStatus',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
.create-contact-status {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    margin-top: 16px;
  }

  &__description {
    font-size: 14px;
  }

  &__content {
    margin-top: 32px;
    width: 100%;

    & > button {
      min-width: 100% !important;
    }
  }
}
</style>
