import { get } from 'lodash/object'
import authUser from '~/modules/auth/authUser'

export default function ({ redirect }) {
  const user = authUser.get()
  const userEmail = get(user, 'email')
  const qaEmails = ['matvii.rostyslav@checkbox.ua', 'matvii.rostyslav@gmail.com']
  if (!qaEmails.includes(userEmail)) {
    return redirect({
      path: '/documents'
    })
  }
}
