import { findIndex, filter, find, get } from 'lodash'
import routes from '~/const/routes'

export const state = () => ({
  items: null,
  flatFoldersTree: [],
  currentFolder: null,
  meta: {
    currentPage: null,
    totalItems: null,
    itemsPerPage: null
  },
  selectedFolder: null
})

export const getters = {
  items: state => state.items,
  meta: state => state.meta,
  flatFoldersTree: state => state.flatFoldersTree,
  currentFolder: state => state.currentFolder,
  selectedFolder: state => state.selectedFolder
}

// TODO: move out
const recursivelyFindFolder = (folder, id) => {
  if (folder.id === id) {
    return folder
  }
  if (!folder.children) {
    return null
  }

  for (const child of folder.children) {
    const found = recursivelyFindFolder(child, id)
    if (found) {
      return found
    }
  }
  return null
}

export const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
  },
  SET_META(state, meta) {
    state.meta = meta
  },
  SET_CURRENT_FOLDER(state, folder) {
    state.currentFolder = folder
  },
  ADD_FOLDER(state, { folder, parentId = null }) {
    if (parentId) {
      const firstFolder = state.flatFoldersTree[0] || state.selectedFolder
      const rootFolder = find(state.items, item => item.id === firstFolder.id)
      const parentFolder = recursivelyFindFolder(rootFolder, parentId)
      if (parentFolder.id === parentId) {
        parentFolder.children.push(folder)
      }
    } else {
      state.items.push(folder)
    }
  },
  UPDATE_FOLDER(state, { updatedFolder, parentId = null }) {
    if (parentId) {
      const firstFolder = state.flatFoldersTree[0]
      const rootFolder = find(state.items, item => item.id === firstFolder.id)
      const folder = recursivelyFindFolder(rootFolder, updatedFolder.id)
      if (folder.id === updatedFolder.id) {
        folder.name = updatedFolder.name
      }
    } else {
      const folder = find(state.items, item => item.id === updatedFolder.id)
      folder.name = updatedFolder.name
    }
  },
  REMOVE_FOLDER(state, { id, parentId = null }) {
    if (parentId) {
      const firstFolder = state.flatFoldersTree[0]
      const rootFolder = find(state.items, item => item.id === firstFolder.id)
      let parentFolder
      if (parentId === id) {
        // handle delete active folder (last folder in flatFoldersTree)
        const parentOfActiveFolder = state.flatFoldersTree[state.flatFoldersTree.length - 2]
        parentFolder = recursivelyFindFolder(rootFolder, parentOfActiveFolder?.id)
      } else {
        parentFolder = recursivelyFindFolder(rootFolder, parentId)
      }
      if (parentFolder) {
        parentFolder.children = filter(parentFolder.children, child => child.id !== id)
        state.flatFoldersTree.splice(-1, 1)
      }
    } else {
      state.items = filter(state.items, item => item.id !== id)
    }
  },
  ADD_FOLDER_TO_FLAT_LIST(state, folder) {
    state.flatFoldersTree.push(folder)
  },
  RESET_FLAT_LIST(state) {
    state.flatFoldersTree = []
  },
  SPLICE_FLAT_LIST(state, itemIdx) {
    state.flatFoldersTree.splice(itemIdx + 1)
  },
  RESET(state) {
    state.items = []
    state.meta = null
    state.flatFoldersTree = []
    state.currentFolder = null
  },
  SET_SELECTED_FOLDER(state, folder) {
    state.selectedFolder = folder
  },
  RESET_SELECTED_FOLDER(state) {
    state.selectedFolder = null
  }
}

export const actions = {
  // CRUD operations
  async getAll({ commit }, { config = {} }) {
    const { data, meta } = get(await this.$axios.get(routes.folder.list(), config), 'data')

    commit('SET_ITEMS', data)
    commit('SET_META', meta)

    return { data, meta }
  },
  async create({ commit }, { payload, config = {} }) {
    const folder = get(await this.$axios.post(routes.folder.list(), payload, config), 'data')

    commit('ADD_FOLDER', {
      folder: { ...folder, children: [] },
      parentId: payload.parentId
    })

    return folder
  },
  async update({ commit }, { id, payload, config = {} }) {
    const updatedFolder = get(
      await this.$axios.put(routes.folder.concrete(id), payload, config),
      'data'
    )

    commit('UPDATE_FOLDER', { updatedFolder, parentId: payload.parentId })
  },
  async delete({ commit, getters }, { id, parentId, config = {} }) {
    await this.$axios.delete(routes.folder.concrete(id), config)
    commit('REMOVE_FOLDER', { id, parentId })
    if (id === parentId) {
      commit('SET_CURRENT_FOLDER', getters.flatFoldersTree[getters.flatFoldersTree.length - 1])
    }
  },
  // common actions
  updateFoldersBreadcrumbs({ state, commit }, { folder }) {
    if (folder) {
      const breadcrumbIndex = findIndex(state.flatFoldersTree, item => item.id === folder.id)
      if (breadcrumbIndex === -1) {
        commit('ADD_FOLDER_TO_FLAT_LIST', folder)
      } else {
        commit('SPLICE_FLAT_LIST', breadcrumbIndex)
      }
    } else {
      commit('RESET_FLAT_LIST')
    }
  }
}
