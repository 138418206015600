import { get } from 'lodash/object'

export default function ({ route, redirect, store }) {
  const proVersionRoutes = ['contacts']
  const isProVersionRote = route && proVersionRoutes.includes(route.name)

  const hasProVersion = get(store, 'state.organizations.currentOrganization.hasProVersion', false)

  if (isProVersionRote && !hasProVersion) {
    return redirect({
      name: 'documents'
    })
  }
}
