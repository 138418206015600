<template lang="pug">
  div
    h2(class="title-32px mb-2") {{ $t('Approval route') }}
    p(class="text-14px") {{ $t('Indicate sequentially the colleagues from whom you need to obtain approval') }}
    v-form(@submit.prevent="submit")
      ValidationObserver(
        ref="approversForm"
        tag="div"
      )
        draggable(
          v-model="approvers"
          handle=".draggable"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
        )
          transition-group(
            type="transition"
            :name="!drag ? 'flip-list' : null"
          )
            div(
              v-for="(item, idx) in approvers"
              :key="item.localId"
              class="approver"
              :class="{ many: approvers.length > 1 }"
            )
              div(
                v-if="approvers.length > 1"
                class="approver__dots draggable"
              )
                e-svg-icon six-dots-vertical
              ValidationProvider(
                tag="div"
                v-slot="{ errors }"
                vid="email"
                :name="wrapQuotesProviderName($t('Enter recipient`s email'))"
                mode="lazy"
                rules="required"
              )
                v-text-field(
                  v-model="item.email"
                  filled
                  :label="$t('Enter recipient`s email')"
                  hide-details="auto"
                  :error-messages="errors"
                )
              v-btn(
                v-if="approvers.length > 1"
                icon
                class="secondary-button error-btn approver__remove-btn"
                @click="removeApprover(idx)"
              )
                e-svg-icon danger-trash
      div(class="two-buttons-wrapper pt-8")
        v-btn(
          class="secondary-button"
          @click="addApprover"
        ) {{ $t('Add one more recipient') }}
        v-btn(
          :loading="loading"
          class="main-button"
          @click="submit"
        ) {{ $t('Send on approve') }}
</template>

<script>
import Draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import commonDocumentRequests from '~/modules/documents/mixins/request'
import validate from '~/mixins/validation/validate'

export default {
  name: 'FormDocumentApprovers',
  components: {
    Draggable,
    ESvgIcon
  },
  mixins: [commonDocumentRequests, validate],
  props: {
    model: {
      type: Function,
      default: null
    },
    document: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      drag: false,
      approvers: [
        {
          localId: uuidv4(),
          email: null
        }
      ]
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'approver--active'
      }
    }
  },
  methods: {
    addApprover() {
      const newApprover = {
        localId: uuidv4(),
        email: null
      }
      this.approvers.push(newApprover)
    },
    removeApprover(idx) {
      this.approvers.splice(idx, 1)
    },
    async submit() {
      try {
        this.loading = true
        await this.model.api().sendOnApprovals(this.document.id, { participants: this.approvers })
        await this.fetchDocumentParticipants(this.document.id)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.approver {
  display: grid;
  grid-template-columns: 1fr;

  padding: 12px;

  position: relative;

  border-radius: 12px;
  border: 2px dashed #e3e3e3;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &--active {
    border: 2px dashed $primary;

    .form-document-approvers__approver-dots {
      background: rgba(143, 37, 125, 0.16);
      border: 1px solid rgba(143, 37, 125, 0.16);
    }
  }

  &.many {
    grid-template-columns: 1fr 48px;
    gap: 16px;
    padding-left: 42px;
  }

  &__dots {
    width: 24px;
    height: 100%;
    background: #f5f6f7;
    border-radius: 12px 0 0 12px;
    border: 1px solid #f5f6f7;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  &__remove-btn {
    width: 100%;
  }
}
</style>
