const baseUrl = 'https://my.dubidoc.com.ua'
const MOBILE_APP_LINK = {
  ANDROID: {
    store: 'https://play.google.com/store/apps/details?id=ua.checkbox.edo',
    deepLink: `${baseUrl}/open-main`
  },
  IOS: {
    store: 'https://apps.apple.com/app/dubidoc/id6501991971',
    deepLink: `${baseUrl}/open-main`
  },
  DEFAULT: 'documents'
}

export default function ({ redirect }) {
  const userAgent = navigator.userAgent.toLowerCase()

  const isAndroid = /android/i.test(userAgent)
  const isIOS = /ipad|iphone|ipod/i.test(userAgent)

  const redirectToStore = platform => {
    window.location.replace(platform.store)
  }

  const tryToOpenApp = platform => {
    const hiddenBefore = document.hidden

    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = platform.deepLink
    document.body.appendChild(iframe)

    setTimeout(() => {
      document.body.removeChild(iframe)

      if (!document.hidden || document.hidden === hiddenBefore) {
        redirectToStore(platform)
      }
    }, 1500)
  }

  if (isIOS) {
    setTimeout(() => tryToOpenApp(MOBILE_APP_LINK.IOS), 100)
  } else if (isAndroid) {
    setTimeout(() => tryToOpenApp(MOBILE_APP_LINK.ANDROID), 100)
  } else {
    redirect({ name: MOBILE_APP_LINK.DEFAULT })
  }
}
