<template lang="pug">
  div(
    :class="['qr-code-promo-wrapper', { opened: isQrVisible }]"
    v-if="isOpen"
  )
    div(class="qr-code-promo")
      div(class="qr-code-promo-title")
        | {{ isQrVisible ? $t('Scan QR Code') : $t('Install app') }}
        e-svg-icon(
          class="qr-code-promo-icon"
          name="close"
          @click="isQrVisible ? toggleQr() : closeQr()"
        )
      div(class="qr-code-promo-text")
        | {{ isQrVisible ? $t('Scan QR Code description') : $t('Install app description') }}
      template(v-if="isQrVisible")
        QrcodeVue(
          :value="qrCodeData"
          :size="106"
          level="H"
          class="qr-code-promo-code"
        )
      template(v-else)
        v-btn(
          class="outlined-button qr-code-promo-button"
          @click="toggleQr"
        ) {{ $t('Install') }}
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'MBlockQrCodePromo',
  components: { QrcodeVue },
  data: () => ({
    isQrVisible: false,
    isOpen: true
  }),
  computed: {
    qrCodeData() {
      return `${window.location.origin}/install`
    }
  },
  methods: {
    toggleQr() {
      this.isQrVisible = !this.isQrVisible
    },
    closeQr() {
      this.isQrVisible = false
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code-promo {
  padding: 12px;
  width: 100%;
  height: 100%;
  position: relative;

  &-wrapper {
    height: 196px;
    width: 184px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 12px;
    background: #f8f2f7 url('~~/assets/images/phone.png') no-repeat right bottom;

    &.opened {
      background: #f8f2f7;
    }
  }

  &-title {
    font-family: 'mariupol-regular', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #121212;
    position: relative;
  }

  &-text {
    font-family: 'mariupol-regular', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #676767;
    padding-top: 4px;
    max-width: 140px;
  }

  &-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }

  &-button {
    position: absolute;
    bottom: 12px;
    left: 12px;
  }

  &-code {
    padding-top: 12px;
  }
}
</style>
