import Vue from 'vue'
import { cloneDeep, filter, map } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import TemplateField from '~/modules/templates/models/TemplateField'

export const state = () => ({
  fields: [],
  fieldsToDelete: [],
  lastActiveFieldId: null,
  activeField: {
    id: null,
    offsetX: null,
    offsetY: null
  },
  copiedField: null,
  cuttedField: null,
  isDraggingMap: {},
  toolbarsMap: {},
  filledFields: [],
  isGuideTour: false,
  errorFieldIds: [],
  checkOnSaveValidation: false,
  validationResolver: null
})

export const getters = {
  fields: (state) => state.fields,
  fieldsToDelete: (state) => state.fieldsToDelete,
  activeFieldId: (state) => state.activeField.id,
  activeFieldOffsetX: (state) => state.activeField.offsetX,
  activeFieldOffsetY: (state) => state.activeField.offsetY,
  lastActiveFieldId: (state) => state.lastActiveFieldId,
  copiedField: (state) => state.copiedField,
  cuttedField: (state) => state.cuttedField,
  isDraggingMap: (state) => state.isDraggingMap,
  toolbarsMap: (state) => state.toolbarsMap,
  filledFields: (state) => state.filledFields,
  isGuideTour: (state) => state.isGuideTour,
  errorFieldIds: (state) => state.errorFieldIds,
  checkOnSaveValidation: (state) => state.checkOnSaveValidation,
  validationResolver: (state) => state.validationResolver
}

export const mutations = {
  CREATE_FIELD(state, { field, page }) {
    const payload = { ...field, page, id: uuidv4() }
    state.fields.push(payload)
  },
  ADD_FIELD(state, field) {
    state.fields.push(field)
  },
  UPDATE_FIELD(state, { id, updatedData }) {
    state.fields = map(state.fields, (field) =>
      field.id === id ? { ...field, ...updatedData } : field
    )
  },
  SET_FIELD_QUICK_VALUE_BY_FIELD_ID(state, { id, value }) {
    state.fields = map(state.fields, (field) =>
      field.id === id ? { ...field, quickValue: value } : field
    )
  },
  DELETE_FIELD(state, fieldId) {
    state.fields = filter(state.fields, (field) => field.id !== fieldId)
  },
  RESET_FIELDS(state) {
    state.fields = []
  },
  SET_ACTIVE_FIELD_ID(state, id) {
    state.activeField.id = id
  },
  SET_LAST_ACTIVE_FIELD_ID(state, id) {
    state.lastActiveFieldId = id
  },
  SET_ACTIVE_FIELD_OFFSET_X(state, offset) {
    state.activeField.offsetX = offset
  },
  SET_ACTIVE_FIELD_OFFSET_Y(state, offset) {
    state.activeField.offsetY = offset
  },
  SET_COPIED_FIELD(state, field) {
    state.copiedField = field
  },
  SET_CUTTED_FIELD(state, field) {
    state.cuttedField = field
  },
  // TODO: rethink maybe
  SET_DRAGGING_MAP_FIELD_ID(state, { id, value }) {
    Vue.set(state.isDraggingMap, id, value)
  },
  SET_TOOLBARS_MAP_FIELD_ID(state, { id, value }) {
    Vue.set(state.toolbarsMap, id, value)
  },
  REMOVE_DRAGGING_MAP_FIELD(state, id) {
    Vue.delete(state.isDraggingMap, id)
  },
  REMOVE_TOOLBARS_MAP_FIELD(state, id) {
    Vue.delete(state.toolbarsMap, id)
  },
  SET_FILLED_FIELD(state, field) {
    const currentField = state.filledFields.find((f) => f.id === field.id)
    if (currentField) {
      currentField.value = field.value
    } else {
      state.filledFields.push(field)
    }
  },
  REMOVE_FILLED_FIELD(state, fieldId) {
    state.filledFields = state.filledFields.filter((field) => field.id !== fieldId)
  },
  RESET_FILLED_FIELDS(state) {
    state.filledFields = []
  },
  RESET_ALL_TOOLBARS_EXCEPT_ACTIVE(state, fieldId) {
    Object.entries(state.toolbarsMap).forEach(([id, value]) => {
      if (fieldId !== id && value) {
        state.toolbarsMap[id] = false
      }
    })
  },
  SET_IS_GUIDE_TOUR(state, value) {
    state.isGuideTour = value
  },
  SET_ERROR_FIELD_IDS(state, elemId) {
    const currentField = state.errorFieldIds.find((id) => id === elemId)
    if (!currentField) {
      state.errorFieldIds.push(elemId)
    }
  },
  REMOVE_ERROR_FIELD_IDS(state, elemId) {
    state.errorFieldIds = state.errorFieldIds.filter((id) => id !== elemId)
  },
  RESET_ERROR_FIELD_IDS(state) {
    state.errorFieldIds = []
  },
  CHECK_ON_SAVE_VALIDATION(state, value) {
    state.checkOnSaveValidation = value
  },
  SET_VALIDATION_RESOLVER(state, resolveFn) {
    state.validationResolver = resolveFn
  },
  SET_FIELD_TO_DELETE(state, field) {
    state.fieldsToDelete.push(field)
  },
  RESET_FIELDS_TO_DELETE(state) {
    state.fieldsToDelete = []
  }
}

export const actions = {
  pasteField({ commit, state }, { field, documentScale }) {
    const COPY_OFFSET = 15
    const clonedField = cloneDeep(field)
    const lastYFieldPosition =
      field.status === TemplateField.STATUSES.created
        ? clonedField.positionY * documentScale
        : clonedField.positionY
    const fieldElement = document.querySelector(`#${field.key}`)
    const openedElementHeight = fieldElement.querySelector('.opened')?.offsetHeight
    let nextYFieldPosition =
      field.status === TemplateField.STATUSES.created
        ? clonedField.positionY * documentScale + COPY_OFFSET
        : clonedField.positionY + COPY_OFFSET
    if (field.type === TemplateField.TYPES.formula || field.type === TemplateField.TYPES.select) {
      // handle case where select or formula is opened and need to duplicate element and duplicate place after element
      nextYFieldPosition += openedElementHeight
      const canvasHeight = document.querySelector('canvas').height
      if (nextYFieldPosition > canvasHeight) {
        // handle case if select or formula opened at the end of the page, so place duplicate before element
        nextYFieldPosition = clonedField.positionY - clonedField.height - COPY_OFFSET
      }
    } else {
      // handle all other fields where no opened body
      nextYFieldPosition +=
        field.status === TemplateField.STATUSES.created
          ? clonedField.height * documentScale
          : clonedField.height
    }
    const isCut = !!state.cuttedField
    const pasteField = {
      ...clonedField,
      id: uuidv4(),
      width:
        field.status === TemplateField.STATUSES.created
          ? clonedField.width * documentScale
          : clonedField.width,
      height:
        field.status === TemplateField.STATUSES.created
          ? clonedField.height * documentScale
          : clonedField.height,
      positionX:
        field.status === TemplateField.STATUSES.created
          ? clonedField.positionX * documentScale
          : clonedField.positionX,
      positionY: isCut ? lastYFieldPosition : nextYFieldPosition,
      key: clonedField.key + '_' + Math.ceil(Math.random() * 1000) // TODO: might be changed
    }
    if (isCut && pasteField.status === TemplateField.STATUSES.to_delete) {
      pasteField.status = TemplateField.STATUSES.created
    } else {
      pasteField.status = TemplateField.STATUSES.new
    }
    commit('ADD_FIELD', pasteField)
  }
}
