<template lang="pug">
  v-navigation-drawer(
    :value="isOpen"
    app
    disable-resize-watcher
    width="100%"
    :height="height"
    bottom
    class="drawer-mobile"
    :style="{ zIndex: 5, maxHeight: maxHeight }"
  )
    div(class="drawer-mobile__header")
      v-btn(
        icon
        class="drawer-mobile__close"
        @click="close"
      )
        e-svg-icon(
          name="cross-close-dark"
          size="lg"
        )
      h4(class="drawer-mobile__title") {{ $t(title) }}
    div(
      class="drawer-mobile__body"
      :style="{ backgroundColor: background }"
    )
      component(
        :is="component"
        v-bind="componentProps"
        :close-drawer="close"
        @close="close"
      )
</template>

<script>
import MobileDrawer from '~/modules/mobile-drawer/models/MobileDrawer'
import FormDocumentRename from '~/modules/documents/components/forms/form-document-rename'
import FormDocumentSignatures from '~/modules/documents/components/forms/form-document-signatures'
import MDocumentShare from '~/modules/documents/components/modules/m-document-share'
import FormDocumentsFiltersMobile from '~/modules/documents/components/forms/form-documents-filters-mobile'

export default {
  name: 'MobileDrawer',
  components: {
    FormDocumentRename,
    FormDocumentSignatures,
    MDocumentShare,
    FormDocumentsFiltersMobile
  },
  computed: {
    drawer() {
      return MobileDrawer.query().first()
    },
    isOpen() {
      return this._.get(this.drawer, 'isOpen', false)
    },
    params() {
      return this._.get(this.drawer, 'params', '')
    },
    title() {
      return this._.get(this.params, 'title', '')
    },
    height() {
      return this.maxHeight !== 'auto' ? '100%' : 'auto'
    },
    background() {
      return this._.get(this.params, 'background', '#fff')
    },
    maxHeight() {
      return this._.get(this.params, 'height', 'auto')
    },
    component() {
      return this._.get(this.params, 'component')
    },
    componentProps() {
      return this._.get(this.params, 'componentProps')
    }
  },
  created() {
    if (!this.drawer) {
      MobileDrawer.new()
    }
  },
  methods: {
    close() {
      this.drawer.close()
    }
  }
}
</script>

<style scoped lang="scss">
.drawer-mobile {
  border-radius: 16px 16px 0 0 !important;

  &__close {
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 5;
  }

  &__header {
    position: relative;
    padding: 12px 16px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);

    &-btn {
      position: absolute;
      top: 50%;
      right: 15px;
      z-index: 5;
      transform: translateY(-50%);

      color: #000;
      font-size: 14px !important;
      font-weight: 300;
      line-height: 24px;
      text-decoration: underline;
    }
  }

  &__body {
    height: 100%;
    padding: 16px;
  }

  &__title {
    color: $black;
    font-variant-numeric: slashed-zero;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
