<template lang="pug">
  v-btn(
    class="main-button w-100 mb-6"
    @click="openCreateContactDialog"
  ) {{ $t('Create contact') }}
</template>

<script>
import contentDialog from '~/mixins/dialogs/contentDialog'
import Contact from '~/modules/contacts/models/Contact'

export default {
  name: 'EButtonContactCreate',
  mixins: [contentDialog],
  computed: {
    model() {
      return Contact
    }
  },
  methods: {
    openCreateContactDialog() {
      this.contentDialog.open({
        component: 'm-create-contact',
        width: '640px',
        componentProps: {
          title: this.$t('Create contact'),
          onSuccess: () =>
            this.contentDialog.open({
              component: 'm-create-contact-status',
              width: '512px',
              componentProps: {
                title: this.$t('Contact created'),
                description: this.$t('Contact created description')
              }
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
