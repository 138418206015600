const participants = {
  computed: {
    STATUSES() {
      return {
        CREATED: 'created',
        PENDING: 'pending',
        SIGNED: 'signed',
        VIEWED: 'viewed',
        FILLED: 'filled',
        APPROVED: 'approved',
        REJECTED: 'rejected',
        CANCELLED: 'cancelled'
      }
    },
    participantActionsMap() {
      return {
        VIEW: 'view',
        SIGN: 'sign',
        FILL: 'fill',
        APPROVE: 'approve'
      }
    },
    participantsRoleActionMap() {
      return {
        DOCUMENT_SIGNER: this.participantActionsMap.SIGN,
        DOCUMENT_APPROVER: this.participantActionsMap.APPROVE,
        DOCUMENT_VIEWER: this.participantActionsMap.VIEW
      }
    },
    participantsRoleLabelMap() {
      return {
        DOCUMENT_SIGNER: this.$t('Signature'),
        DOCUMENT_APPROVER: this.$t('Approve'),
        DOCUMENT_VIEWER: this.$t('View')
      }
    },
    participantActions() {
      return [
        {
          text: this.$t('To view'),
          value: 'view'
        },
        {
          text: this.$t('Sign'),
          value: 'sign'
        },
        {
          text: this.$t('To fill'),
          value: 'fill'
        },
        {
          text: this.$t('To approve'),
          value: 'approve'
        }
      ]
    },
    proParticipantActions() {
      return this._.filter(
        this.participantActions,
        action => action.value !== this.participantActionsMap.FILL
      )
    }
  },
  methods: {
    setParticipantIdentifier(value) {
      return isFinite(value) && value.length >= 8 && value.length <= 10 ? 'edrpou' : 'email'
    }
  }
}

export default participants
