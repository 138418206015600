import DocumentSidebar from '~/modules/documents/models/DocumentSidebar'

const documentSidebar = {
  computed: {
    documentSidebar () {
      return DocumentSidebar.query().first()
    }
  }
}

export default documentSidebar
