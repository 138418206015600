<template lang="pug">
  v-app
    template(v-if="xsDevice")
      the-header(
        @toggleDrawer="toggleDrawer"
        :is-drawer-visible="drawer"
      )
      drawer-mobile(
        :value="drawer"
        @closeDrawer="drawer = false"
      )
    drawer(v-else)
    v-main
      v-container(
        fill-height
        fluid
        class="default pa-0 align-start bg"
      )
        nuxt
    notification
    dialogs(ref="dialogs")
    sidebars
    snackbars
    guidelines
    mobile-drawer
    ghost
</template>

<script>
import notification from './includes/notification'
import dialogs from './includes/dialogs'
import drawer from '~/layouts/includes/drawer'
import TheHeader from '~/layouts/includes/header'
import sidebars from '~/modules/sidebar/views/components/sidebars'
import responsive from '~/mixins/pages/responsive'
import snackbars from '~/layouts/includes/snackbars'
import DrawerMobile from '~/layouts/includes/drawer-mobile'
import guidelines from '~/layouts/includes/guidelines'
import templateOnboarding from '~/modules/templates/mixins/templateOnboarding'
import MobileDrawer from '~/layouts/includes/mobile-drawer'
import Ghost from '~/layouts/includes/ghost'

export default {
  components: {
    Ghost,
    MobileDrawer,
    snackbars,
    drawer,
    notification,
    dialogs,
    sidebars,
    TheHeader,
    DrawerMobile,
    guidelines
  },
  mixins: [templateOnboarding, responsive],
  data() {
    return {
      drawer: false
    }
  },
  mounted() {
    this.checkShowTemplatesOnboarding()
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  min-width: 320px !important;
  overflow: hidden;
}
.bg {
  background: $background-primary;
}
</style>
