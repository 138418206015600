<template lang="pug">
  div(class="template-share")
    h2(class="title-32px mb-2") {{ $t('Public template') }}
    div(class="text-16px") {{ $t('Clients fill template by themself and you get prepared document') }}
    div(class="template-share__qr-wrapper")
      qrcode-vue(
        v-if="qrUrl"
        ref="qrCode"
        :value="qrUrl"
        :size="300"
        class="template-share__qr-code"
      )
    div(class="template-share__banner")
      div(class="template-share__banner-content")
        h3(class="template-share__banner-title") {{ $t('Scan quickly and conveniently') }}
        div(class="template-share__banner-row mb-3")
          v-img(
            :src="require('~/assets/images/link.png')"
            max-width="24"
          )
          div(class="text-14px ml-4") {{ $t('Share link or QR-code') }}
        div(class="template-share__banner-row")
          v-img(
            :src="require('~/assets/images/message-pen.png')"
            max-width="24"
          )
          div(class="text-14px ml-4") {{ $t('Get filled documents without any time loses!') }}
      div(class="template-share__banner-image")
        v-img(
          :src="require('~/assets/images/qr-code.png')"
          max-width="300"
        )
    div(class="template-share__actions")
      v-btn(
        height="48"
        :loading="loading"
        @click="downloadQR"
      )
        e-svg-icon(class="mr-1") arrow-download
        span {{ $t('Download QR-code') }}
      v-btn(
        class="main-button"
        @click="handleCopyLink"
      )
        e-svg-icon(class="mr-1") link-white
        span {{ $t('Copy link') }}
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import Download from 'js-file-download'
// import JSZip from 'jszip'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import clipboard from '~/mixins/global/_clipboard'
import successSnackbar from '~/modules/snackbar/mixins/successSnackbar'

export default {
  name: 'BlockTemplateShare',
  components: {
    ESvgIcon,
    QrcodeVue
  },
  mixins: [clipboard, successSnackbar],
  data() {
    return {
      qrUrl: null,
      loading: false
    }
  },
  mounted() {
    this.qrUrl = this.$config.webURL + this.$route.fullPath
  },
  methods: {
    async handleCopyLink() {
      this.copy(this.qrUrl)
      await this.successSnackbar.open({
        text: this.$t('Link copied'),
        icon: 'copy-success-check',
        timeout: 2000
      })
    },
    downloadQR() {
      const canvas = this.$refs.qrCode.$el.querySelector('canvas')
      canvas.toBlob((blob) => {
        Download(blob, 'template-qr.png')
      }, 'image/png')
      // TODO: future plans move to several qr codes download
      // const zip = new JSZip()
      // eslint-disable-next-line require-await
      // const addFileToZip = async (qrCode) => {
      //   return new Promise((resolve, reject) => {
      //     const canvas = qrCode.$el.querySelector('canvas')
      //     const ctx = canvas.getContext('2d')
      //     const logo = new Image()
      //     const isBlackQrBg = qrCode.foreground === '#00000'
      //     console.log(qrCode.foreground)
      //     if (isBlackQrBg) {
      //       logo.src = require('~/assets/images/logo/dubidoc-logo-box-black.png')
      //     } else {
      //       logo.src = require('~/assets/images/logo/dubidoc-logo-box-primary.png')
      //     }
      //     logo.onload = () => {
      //       const logoSize = 100
      //       const centerX = (canvas.width - logoSize) / 2
      //       const centerY = (canvas.height - logoSize) / 2
      //       ctx.drawImage(logo, centerX, centerY, logoSize, logoSize)
      //       canvas.toBlob((blob) => {
      //         if (blob) {
      //           zip.file(`${isBlackQrBg ? 'template-qr-black' : 'template-qr-primary'}.png`, blob)
      //           resolve()
      //         } else {
      //           reject(new Error(this.$t('Failed to create QR-code')))
      //         }
      //       }, 'image/png')
      //     }
      //     logo.onerror = () => reject(new Error(this.$t('Failed to load image')))
      //   })
      // }
      // const promises = this.$refs.qrCodes.map(addFileToZip)
      // try {
      //   const s = await Promise.all(promises)
      //   console.log(s)
      //   const content = await zip.generateAsync({ type: 'blob' })
      //   saveAs(content, 'qrcodes.zip')
      // } catch (e) {
      //   this.$handlers.error(e, this)
      // }
    }
  }
}
</script>

<style scoped lang="scss">
.template-share {
  &__banner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 32px 0;
    border-radius: 16px;
    background: linear-gradient(
      275deg,
      rgba(143, 37, 125, 0.5) 0%,
      rgba(234, 174, 224, 0.5) 50.29%,
      #fff 99.99%
    );

    &-content {
      padding-left: 32px;
    }

    &-row {
      display: flex;
      align-items: flex-start;
    }

    &-title {
      color: $primary;
      font-variant-numeric: slashed-zero;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }
  &__qr {
    &-code {
      visibility: hidden;
      opacity: 0;
      position: absolute;
    }
  }
  &__actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }
  }
}
</style>
