<template lang="pug">
  div(class="form-document-reject")
    div(class="form-document-reject__image")
      v-img(:src="require('~/assets/images/warning.png')" :max-width="80" class="mb-4")
    h2(class="title-32px") {{ $t('Reject document') }}
    p(class="text-14px") {{ $t('Please provide a reason for rejecting the document. This comment will only be visible to the owner') }}
    v-form(@submit.prevent="submit")
      ValidationProvider(
        rules="required"
        :name="wrapQuotesProviderName($t('Enter reason of document reject'))"
        vid="reason"
        ref="reasonField"
        v-slot="{ errors }"
        mode="passive"
      )
        v-textarea(
          v-model="reason"
          filled
          :error-messages="errors"
          :label="$t('Enter reason of document reject')"
          height="100"
        )
      div(class="form-document-reject__actions")
        v-btn(height="48" @click="closeModal(null)") {{ $t('Come back') }}
        v-btn(class="secondary-button error-btn" type="submit") {{ $t('Reject') }}
</template>

<script>
import validate from '~/mixins/validation/validate'

export default {
  name: 'FormDocumentRejectConfirm',
  mixins: [validate],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      reason: null
    }
  },
  methods: {
    async submit () {
      const { valid } = await this.$refs.reasonField.validate()
      if (!valid) {
        return
      }
      this.closeModal(this.reason)
    }
  }
}
</script>

<style scoped lang="scss">
.form-document-reject {
  &__image {
    display: flex;
    justify-content: center;
  }

  &__actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }
  }
}
</style>
