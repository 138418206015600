import CurrentUser from '~/modules/auth/models/CurrentUser'
import User from '~/models/administration/User'

export default {
  api: {
    async load () {
      const user = await CurrentUser.api().getCurrent()
      User.insert({ data: CurrentUser.query().withAll().first() })
      return user
    }
  },
  get () {
    return CurrentUser.query().withAll().first()
  }
}
