const filters = {
  data() {
    return {
      selectedFilters: {
        search: null,
        folder: null,
        statuses: [],
        createdAt: []
      }
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(newQueries) {
        if (newQueries.statuses) {
          this.selectedFilters.statuses = newQueries.statuses.split(',')
        }
        if (newQueries.createdAt) {
          this.selectedFilters.createdAt = newQueries.createdAt.split(',')
        }
        if (newQueries.folder) {
          this.selectedFilters.folder = newQueries.folder
        }
        if (newQueries.search) {
          this.selectedFilters.search = newQueries.search
        }
        this.updateFilters(this._.cloneDeep(this.selectedFilters))
      }
    }
  },
  methods: {
    updateFilters(filters) {
      this.$emit('input', filters)
      this.$store.commit('documents/SET_FILTERS', filters)
    },
    resetFilters() {
      if (this.selectedFilters) {
        this.selectedFilters.search = ''
        this.selectedFilters.folder = null
        this.selectedFilters.statuses = []
        this.selectedFilters.createdAt = []
        this.handleQuery()
        const copyFilters = this._.cloneDeep(this.selectedFilters)
        this.updateFilters(copyFilters)
      }
    },
    setFilters(filters) {
      this.handleQuery()
      const copyFilters = this._.cloneDeep(filters)
      if (copyFilters.folder === 'all') {
        // due to v-radio can't have value Null
        copyFilters.folder = null
      }
      this.updateFilters(copyFilters)
    },
    handleQuery() {
      const queries = {
        search: this.selectedFilters.search,
        folder: this.selectedFilters.folder,
        createdAt: this.selectedFilters.createdAt.join(','),
        statuses: this.selectedFilters.statuses.join(',')
      }
      if (queries.search === null || queries.search === '') {
        delete queries.search
      }
      if (queries.folder === null || queries.folder === '' || queries.folder === 0) {
        delete queries.folder
      }
      if (queries.createdAt === '') {
        delete queries.createdAt
      }
      if (queries.statuses === '') {
        delete queries.statuses
      }
      return this.$router.replace({ query: queries }).catch(() => {})
    }
  }
}

export default filters
