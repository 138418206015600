export const menuItems = [
  {
    icon: 'file-1',
    activeIcon: 'file-active',
    href: '/documents',
    text: 'Documents'
  },
  {
    icon: 'template',
    activeIcon: 'template-active',
    href: '/templates',
    text: 'Templates'
  },
  {
    icon: 'archive-3',
    activeIcon: 'archive-active',
    href: '/documents/archive',
    text: 'Archive'
  }
]

const [documents, template, archive] = menuItems

export const menuItemsPro = [
  documents,
  template,
  {
    icon: 'contacts',
    activeIcon: 'contacts-active',
    href: '/contacts',
    text: 'Contacts',
    isNew: true
  },
  archive
]
