import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'

import helpers from '~/plugins/vuex-orm/plugins/helpers'
import ormTable from '~/plugins/vuex-orm/plugins/orm-table'
import queries from '~/plugins/vuex-orm/plugins/queries'
import VuexORMAxiosConfig from '~/plugins/vuex-orm/plugins/vuex-orm-axios-config'

import { actions } from '~/const/global'

// TODO: create automatic import of models.
import MediaObject from '~/models/system/MediaObject'
import Dialog from '~/models/system/Dialog'
import Sidebar from '~/modules/sidebar/models/Sidebar'
import Notifications from '~/modules/notifications/models/Notifications'
import Document from '~/modules/documents/models/Document'
import User from '~/models/administration/User'
import AuthToken from '~/modules/auth/models/AuthToken'
import CurrentUser from '~/modules/auth/models/CurrentUser'
import Organization from '~/modules/organizations/models/Organization'
import OrganizationUsers from '~/modules/organizations/models/OrganizationUsers'
import OrganizationInvite from '~/modules/organizations/models/OrganizationInvite'
import EdsIitCheckbox from '~/modules/eds-iit-checkbox/models/EdsIitCheckbox'
import OrganizationSearch from '~/models/directories/search/OrganizationSearch'
import Participant from '~/modules/documents/models/Participant'
import Shortcode from '~/modules/shortcodes/models/Shortcode'
import Tag from '~/modules/tags/models/Tag'
import Snackbar from '~/modules/snackbar/models/Snackbar'
import AccessToken from '~/modules/profile/models/AccessToken'
import BulkSignature from '~/modules/bulk-signature/models/BulkSignature'
import DocumentSignatures from '~/modules/documents/models/DocumentSignatures'
import Template from '~/modules/templates/models/Template'
import MobileDrawer from '~/modules/mobile-drawer/models/MobileDrawer'
import TemplateField from '~/modules/templates/models/TemplateField'
import Guideline from '~/modules/guideline/models/Guideline'
import GalleryCategory from '~/modules/templates/models/GalleryCategory'
import Gallery from '~/modules/templates/models/Gallery'
import DocumentType from '~/modules/documents/models/DocumentType'
import DocumentSidebar from '~/modules/documents/models/DocumentSidebar'
import Comment from '~/modules/documents/models/Comment'
import Contact from '~/modules/contacts/models/Contact'

export const models = {
  MediaObject,
  Dialog,
  Notifications,
  Sidebar,
  Document,
  User,
  AuthToken,
  CurrentUser,
  Organization,
  OrganizationUsers,
  OrganizationInvite,
  EdsIitCheckbox,
  OrganizationSearch,
  Participant,
  Shortcode,
  Tag,
  Snackbar,
  AccessToken,
  BulkSignature,
  DocumentSignatures,
  Template,
  MobileDrawer,
  TemplateField,
  Guideline,
  GalleryCategory,
  Gallery,
  DocumentType,
  DocumentSidebar,
  Comment,
  Contact
}

export const mapping = {
  MediaObject
}

function forEach(callback) {
  for (const index in models) {
    const concrete = models[index]

    callback(concrete)
  }
}

export function mapAbilities(rules, config, mock = true) {
  if (mock) {
    return mockAbilities(config)
  }

  return rules
    ? rules.map(item => {
        if (!(item.subject in mapping)) {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error(
              ['You need create mapping for:', item.subject].join(' ').trim(),
              'entity!'
            )
          }

          return {}
        }

        return Object.assign({}, item, { subject: mapping[item.subject][config.modelKey] })
      })
    : []
}

export function mockAbilities(config) {
  const abilities = []

  forEach(model => {
    abilities.push({ actions: Object.values(actions), subject: model[config.modelKey] })
  })

  return abilities
}

export function vuexORMPlugin(store) {
  VuexORM.use(VuexORMAxios) // <- No axios option.
  VuexORM.use(VuexORMAxiosConfig)
  VuexORM.use(helpers)
  VuexORM.use(ormTable)
  VuexORM.use(queries)

  const database = new VuexORM.Database()

  forEach(model => database.register(model))

  return VuexORM.install(database)
}
