<template lang="pug">
  div
    ValidationProvider(
      v-if="!showUploadedOnly"
      :rules="rules"
      v-slot="{ errors }"
      :name="wrapQuotesProviderName($t('Document'))"
      ref="validation"
    )
      div(
        class="uploader"
        :class="{ errored: errors.length, 'drag-n-drop': dragover }"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
      )
        div(class="uploader__content")
          div(class="title-20px mb-1") {{ $t('Drag files here') }}
          div(class="text-14px mb-6") {{ descriptionFormatText }}
          v-btn(
            class="secondary-button sm"
            @click="openFileMenu"
          ) {{ $t('Choose file') }}
          v-file-input(
            v-show="false"
            ref="file"
            type="file"
            :accept="acceptedFileExtensions"
            :value="file"
            @change="addFile($event)"
          )
      div(
        v-if="errors.length"
        class="text-12px errored pt-2 pl-3"
      ) {{ errors[0] }}
    div(
      v-if="file || uploadedFile"
      class="uploaded-file mt-4"
    )
      div(class="d-flex align-center justify-space-between")
        div(class="d-flex align-center")
          div(
            class="uploaded-file__icon"
            :class="{ 'mr-2': xsDevice, 'mr-4': !xsDevice }"
          )
            v-img(
              :src="require('~/assets/images/file.png')"
              :max-width="xsDevice ? '24px' : '32px'"
              position="center"
              class="my-0 mx-auto"
            )
          div(class="uploaded-file__content")
            h4(class="text-16px dark") {{ fileName }}
            span(
              v-if="file && file.size"
              class="text-14px mr-3"
            ) {{ formatBytes(file.size) }}
            span(class="text-14px uploaded-file__status") {{ $t('Downloaded') }}
        div(class="uploaded-file__actions")
          v-btn(
            v-if="!showUploadedOnly"
            @click.stop="removeFile"
            color="#FFF1F4"
            icon
            class="uploaded-file__btn"
          )
            e-svg-icon(name="danger-trash")
</template>

<script>
import prettyBytes from 'pretty-bytes'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'
import validate from '~/mixins/validation/validate'
import upload from '~/modules/documents/mixins/upload'

export default {
  name: 'EInputDocumentUpload',
  components: {
    ESvgIcon
  },
  mixins: [upload, validate, responsive],
  props: {
    file: {
      type: File,
      default: ''
    },
    uploadedFile: {
      type: Object,
      default: () => ({})
    },
    showUploadedOnly: {
      type: Boolean,
      default: false
    },
    externalText: {
      type: String,
      default: ''
    },
    limitedFormats: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dragover: false
  }),
  computed: {
    fileName() {
      return this.file?.name || this.uploadedFile.name
    },
    descriptionFormatText() {
      return this.externalText
        ? this.externalText
        : this.$t('Supported formats:') + ' JPG,JPEG,PNG,DOC,DOCX,XLS,XLSX,PDF,TXT,P7S'
    }
  },
  methods: {
    openFileMenu() {
      this.$refs.file.$refs.input.click()
    },
    removeFile() {
      this.$emit('update:file', null)
    },
    addFile(file) {
      this.$emit('update:file', file)
    },
    validate() {
      return this.$refs.validation.validate()
    },
    formatBytes(bytes) {
      return prettyBytes(bytes)
    },
    onDrop(e) {
      this.dragover = false

      Array.from(e.dataTransfer.files).forEach(element => {
        const isAllowedExtension = Object.values(this.extensionsMap).includes(element.type)

        if (isAllowedExtension) {
          this.$emit('update:file', element)
        } else {
          this.$handlers.error('File format is not allowed to download', this)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.uploader {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 200px;

  cursor: pointer;
  border-radius: 12px;
  border: 2px dashed $primary;
  background: $white;

  padding: 40px 56px;

  &.errored {
    border-color: $danger;
  }

  &.drag-n-drop {
    border-color: $primary;
  }

  &__content {
    text-align: center;
  }
}

.uploaded-file {
  border: 1px solid #cdcdcd;
  border-radius: 12px;
  padding: 12px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &__icon {
    width: 48px;
    height: 48px;

    background: $background-gray;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__status {
    color: #039855;
  }

  &__btn {
    width: 32px;
    height: 32px;
    border-radius: 8px !important;
    background: #fff1f4 !important;
  }
}
</style>
