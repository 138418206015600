export const state = () => ({
  params: {}
})

export const getters = {
  params: state => state.params
}

export const mutations = {
  SET_PARAMS(state, params) {
    state.params = params
  }
}
