// mport VueI18n from 'vue-i18n'
import { extend, localize } from 'vee-validate'
import IBAN from 'iban'
import { isBase64 } from '~/services/_utils/utils'

// import _ from 'lodash'
// import { extend, configure } from 'vee-validate'
// import { required } from 'vee-validate/dist/rules'
// import enValidations from 'vee-validate/dist/locale/en'
// import ukValidations from 'vee-validate/dist/locale/uk'
//
// const i18n = new VueI18n({
//   locale: 'en',
//   en: {
//     validations: Object.assign({}, enValidations.messages, {
//       alpha_num_dash: 'Field must contain only alpha'
//     })
//   },
//   uk: {
//     validations: Object.assign({}, ukValidations.messages, {
//       alpha_num_dash: 'Field must contain only alpha'
//     })
//   }
// })

// configure({
//   defaultMessage: (field, values) => {
//     values._field_ = i18n.t(`fields.${field}`)
//     return i18n.t(`validations.${values._rule_}`, values)
//   }
// })

localize({
  en: {
    messages: {
      max_barcode: 'The {_field_} should consists of no more 21 characters.',
      valid_barcode: 'The {_field_} should contains "G", "W", "C" characters and mumbers.',
      integer_or_decimal:
        'The {_field_} should as integer or decimal number also can contains ".", "," and two number after.',
      decimal_number: 'The {_field_} should be as fractional or integer number.',
      positive: 'The {_field_} should not be negative or zero.',
      positive_or_zero: 'The {_field_} should be positive number',
      latin_dashes:
        'The field {_field_} may contain only latin letters, digits and symbols "_" and "-"',
      alpha_dash_spaces:
        'The field {_field_} may contain only letters, digits, spaces and symbols "_" and "-"',
      money: '{_field_} should be as xxxxx.xx format',
      phone: '{_field_} is not valid phone',
      base64: '{_field_} is not valid',
      emailsString: '{_field_} is not correct',
      iban: '{_field_} is not correct',
      unique_array_value: 'The value must be unique in the array.',
      not_one_from_list: 'The value is not valid'
    }
  },
  uk: {
    messages: {
      max_barcode: 'Поле {_field_} повинно складатися не більш ніж 21 символ.',
      valid_barcode: 'Поле {_field_} може містити тільки латинські літери "G", "W", "C" та цифри.',
      integer_or_decimal:
        'Поле {_field_} має бути цілим або дробовим числом, може містити ".", "," і два символа після.',
      decimal_number: 'Поле {_field_} повино буди дробовим або цілим числом.',
      positive: 'Поле {_field_} не повинно бути негативним, або дорівнювати нулю.',
      positive_or_zero: 'Поле {_field_} повинно бути цілим, позитивним числом',
      length: 'Поле {_field_} повинно бути довжиною {length} символів',
      integer: 'Поле {_field_} повинно бути цілим числом',
      latin_dashes: 'Поле {_field_} може містити тільки латинські букви, цифри, символи "_" та "-"',
      alpha_dash_spaces:
        'Поле {_field_} може містити тільки букви, цифри, пробіли, символи "_" та "-"',
      money: '{_field_} повинно бути xxxxxx.xx формату',
      inn_passport: '{_field_} маеє бути IПН або серією та номером паспорта',
      phone: '{_field_} має невірний формат',
      base64: '{_field_} невірне',
      emailsString: '{_field_} не відповідає формату. Перевірте правильність введення',
      url: 'Поле {_field_} має бути дійсною адресою сайту',
      is: "Поле {_field_} обов'язкове",
      iban: 'Поле {_field_} невірне',
      unique_array_value: 'Значення вже використовується у списку',
      not_one_from_list: 'Значення не валідне'
    }
  }
})

extend('alpha_num_dash', {
  validate: value => /^[0-9A-Za-z\\-]+$/.test(value),
  message: 'This {_field_} is invalid.'
})

extend('max_barcode', {
  validate(value, { length }) {
    return value.length <= parseInt(length)
  },
  params: ['length']
})

extend('valid_barcode', {
  validate: value => /^[0-9GWCgwc]+$/.test(value)
})

extend('integer_or_decimal', {
  validate: value => {
    return /^[0-9]*[(\\.|\\,){0,1}]?[0-9]{1,2}$/.test(value)
  }
})

extend('decimal_number', {
  validate: value => /^[0-9]*([(\\.|\\,){0,1}][0-9]*)*$/.test(value)
})

extend('positive', {
  validate: value => parseFloat(value.replace(',', '.')) > 0
})

extend('positive_or_zero', {
  validate: value => parseFloat((value + '').replace(',', '.')) >= 0
})

extend('latin_dashes', {
  validate: value => /^[0-9A-Za-z_-]*$/.test(value)
})

extend('alpha_dash_spaces', {
  validate: value => /^[ 0-9A-ZА-ЩЬЮЯЄІЇҐЁ_-]*$/i.test(value)
})

extend('money', {
  validate: value => /^[0-9]{1,18}(\.)?[0-9]{0,2}$/i.test(value)
})

extend('inn_passport', {
  validate: value => {
    return /^[А-ЩЬЮЯҐЄІЇ]{2}\d{6}$/i.test(value) || /^\d{8,10}$/i.test(value)
  }
})
extend('phone', {
  validate: value => {
    return /^\+380\d{2}-\d{3}-\d{2}-\d{2}/i.test(value)
  }
})

extend('base64', {
  validate: value => {
    return isBase64(value)
  }
})

extend('emailsString', {
  validate: value => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+((\s*[;,:|\s]\s*)*([^\s@]+@[^\s@]+\.[^\s@]+)*)*$/.test(value)
  }
})

extend('url', {
  validate: value => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator
    return !!pattern.test(value)
  }
})

extend('iban', {
  validate: value => {
    return IBAN.isValid(value)
  }
})

extend('unique_array_value', {
  params: ['array', 'uniqueKey', 'index'],
  validate(value, { array, uniqueKey, index }) {
    return array.findIndex((item, i) => item[uniqueKey] === value && i !== index) === -1
  }
})

extend('not_one_from_list', {
  params: ['array'],
  validate(value, { array }) {
    return !array.includes(value)
  }
})
