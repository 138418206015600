import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export default class Participant extends ChainInheritance(OrmModel, []) {
  static entity = 'participant'
  static paginated = true

  static ROLES = {
    OWNER: 'ROLE_OWNER',
    SIGNER: 'DOCUMENT_SIGNER',
    APPROVER: 'DOCUMENT_APPROVER',
    VIEWER: 'DOCUMENT_VIEWER',
    FILLER: 'DOCUMENT_FILLER'
  }

  static fields () {
    return {
      id: this.attr(null),
      role: this.attr(null),
      status: this.attr(null),
      result: this.attr(null),
      user: this.attr(null),
      edrpou: this.attr(null),
      organization: this.attr(null),
      priority: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.getAllDocumentParticipants = function (documentId) {
        return this.get(Model.$routes.document.participants(documentId))
      }
      configActions.createDocumentParticipant = function ({ documentId, payload = {}, query = {}, saveEntity = false }) {
        return this.post(Model.$routes.document.participants(documentId), payload, {
          save: saveEntity,
          params: query
        })
      }

      return configActions
    }
  }
}
