<template lang="pug">
  div(class="documents-share-results")
    h2(class="title-32px mb-2") {{ $t(title) }}
    div(v-if="failedDocuments.length" class="documents-share-results__failed-documents")
      div(
        v-for="(document, idx) in failedDocuments"
        :key="idx"
        class="documents-share-results__document"
      )
        div(class="d-flex align-center justify-space-between")
          div(class="text-left")
            div(class="documents-share-results__failed-item-title") {{ document.title }}
            div(
              v-for="(error, idx) in document.errors"
              :key="idx"
              class="documents-share-results__failed-item"
              )
              div(class="documents-share-results__failed-item-participant") {{error.participant}}
              div(class="documents-share-results__failed-item-message") {{error.message}}
              e-svg-icon close-circle-red

    div(class="documents-sign-results__actions")
      v-btn(class="main-button w-100" @click="closeModal") {{ $t('Ok') }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  name: 'BlockDocumentsShareResults',
  components: {
    ESvgIcon
  },
  props: {
    failedDocuments: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Documents signed'
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.documents-share-results {
  &__document {
    border-radius: 12px;
    background: $background-secondary-alternative;
    border-bottom: 1px solid white;
    margin-bottom: 10px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }
  &__failed-item {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid white;
    @media ('max-width: 600px') {
      flex-direction: column;
      position: relative;
    }

    > svg {
     @media ('max-width: 600px') {
       position: absolute;
       right: 20px;
       top: 50%;
       transform: translateY(-50%);
     }
    }
  }

  &__failed-item-participant,
  &__failed-item-message {
    width: 50%;

    @media ('max-width: 600px') {
      width: 100%;
    }
  }
  &__failed-item-title {
    color: black;
    font-weight: 700;
    padding-left: 12px;
    padding-top: 12px;
  }
  &__failed-item-message {
    padding-left: 10px;
    color: red;
  }

}
</style>
