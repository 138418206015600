<template lang="pug">
  section(class="folders-treeview")
    h2(class="title-32px mb-2") {{ $t('Add to folder') }}
    p(class="text-14px mb-8") {{ $t('Select folder for moving documents or create a new one') }}
    v-treeview(
      v-if="items && items.length"
      :items="items"
      activatable
      expand-icon="mdi-chevron-down"
      return-object
      @update:active="handleActive"
    )
      template(v-slot:prepend="{ open }")
        div(class="d-flex align-center")
          e-svg-icon(:name="open ? 'folder-open' : 'folder-filled'")
      template(v-slot:append="{ active }")
        div(
          v-if="active"
          class="d-flex align-center"
        )
          e-svg-icon(size="lg") check-primary
    v-btn(
      text
      class="w-100 new-folder-button"
      height="48"
      @click="addFolder"
    )
      e-svg-icon(class="mr-2") folder-plus-primary
      span {{ $t('New folder') }}
    div(class="two-buttons-wrapper buttons")
      v-btn(
        height="48"
        @click="closeModal"
      ) {{ $t('Cancel') }}
      v-btn(
        class="main-button"
        :loading="loading"
        @click="submit"
        :disabled="!selectedFolder"
      ) {{ $t('Add') }}
</template>

<script>
import { mapGetters } from 'vuex'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import documentActions from '~/modules/documents/mixins/documentActions'
import Document from '~/modules/documents/models/Document'
import contentDialogContentTop from '~/mixins/dialogs/contentDialogContentTop'
import informationSnackbar from '~/modules/snackbar/mixins/informationSnackbar'

export default {
  name: 'MFoldersTreeview',
  components: {
    ESvgIcon
  },
  mixins: [documentActions, contentDialogContentTop, informationSnackbar],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    documents: {
      type: Array,
      default: () => []
    },
    // TODO: delete only for DEMO
    newApproach: {
      type: Boolean,
      default: false
    },
    customRequest: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      selectedFolder: null
    }
  },
  computed: {
    ...mapGetters('folders', {
      items: 'items'
    })
  },

  created() {
    if (this.$hasProVersion) {
      this.onCreated()
    }
  },
  methods: {
    async addFolder() {
      await this.contentDialogContentTop.open({
        width: '512px',
        component: 'form-folders',
        componentProps: {
          parentId: this.selectedFolder?.id
        }
      })
    },
    handleActive(activeFolderArr) {
      this.selectedFolder = activeFolderArr[0]

      if (!activeFolderArr || !activeFolderArr.length) {
        this.selectedFolder = null
        this.$store.commit('folders/RESET_SELECTED_FOLDER')
      } else {
        this.$store.commit('folders/SET_SELECTED_FOLDER', activeFolderArr[0])
      }
    },
    async submit() {
      try {
        this.loading = true
        const payload = {
          documentIds: this.documents.map(document => document.id),
          action: 'to-archive',
          folderId: this.selectedFolder?.id
        }
        await Document.api().archiveDocuments(payload)
        // TODO: delete only for DEMO
        if (this.newApproach) {
          await this.$store.dispatch('documents/getAll', {
            config: {
              params: this.$store.getters['documents/requestParams']
            }
          })
        } else {
          await this.$store.dispatch('documents/updateDocumentsWithSavedParams')
        }
        this.closeModal()
        this.informationSnackbar.open({
          icon: 'check-circle-white-filled',
          text:
            this.documents.length > 1
              ? this.$t('Documents has moved to folder {folderName}', {
                  folderName: this.selectedFolder?.name
                })
              : this.$t('Document has moved to folder {folderName}', {
                  folderName: this.selectedFolder?.name
                })
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async onCreated() {
      try {
        this.isLoading = true
        await this.$store.dispatch('folders/getAll', {
          config: {
            params: {
              offset: 1,
              limit: 30,
              scope: 'archive'
            }
          }
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.folders-treeview {
  &::v-deep {
    .v-treeview-node {
      cursor: pointer;
      &__root {
        border-bottom: 0.5px solid #d4d4d4;
      }

      &--active {
        &:before {
          background: none;
          opacity: 0;
          transition: none;
        }
      }
    }
  }
}

.buttons {
  padding-top: 100px;
}

.new-folder-button {
  border-radius: 0 !important;
  border-bottom: 0.5px solid #d4d4d4;

  &::v-deep {
    .v-btn__content {
      font-weight: 400 !important;
      justify-content: flex-start !important;
      color: $primary !important;
    }
  }
}
</style>
