import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'

export default class Contact extends OrmModel {
  static entity = 'contact'
  static paginated = true
  static defaultSortParam = 'createdAt'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      email: this.attr(null),
      edrpou: this.attr(null),
      createdAt: this.attr(null)
    }
  }

  get handleDate() {
    return this.getDate(this.createdAt)
  }

  static ormHeaders = [
    { text: 'Full name', value: 'name', sortable: true, filterable: true },
    { text: 'Email', value: 'email', sortable: true, filterable: true },
    { text: 'Edrpou', value: 'edrpou', sortable: true, filterable: true },
    {
      text: 'Actions',
      align: 'center',
      value: 'actions',
      width: '120',
      sortable: false,
      filterable: false
    }
  ]

  static ormActions = [
    {
      name: 'edit',
      text: 'Edit',
      icon: 'pen-edit',
      visible: () => true,
      call: async item => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        await contentDialog.open({
          component: 'm-edit-contact',
          width: '640px',
          componentProps: {
            title: 'Edit contact',
            contact: item
          }
        })
      }
    },
    {
      name: 'delete',
      text: 'Delete',
      icon: 'danger-trash',
      visible: () => true,
      call: async item => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        const confirmed = await confirmationDialog.open({
          title: 'Delete contact',
          text: vm => vm.$t('Delete contact "{name}" description', { name: item.name }),
          width: '420px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          }
        })
        if (confirmed) {
          try {
            await Contact.api().del(item)
            AppNotifications.success('Contact has deleted successfully')
          } catch (e) {
            const message =
              this._.get(e, 'response.data.detail', null) ||
              this._.get(e, 'response.data.message', null)
            AppNotifications.error(message)
          }
        }
      }
    }
  ]

  static apiConfig = {
    get actions() {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.import = function (payload) {
        return this.post(Model.$routes.contact.import(), payload, { save: false })
      }
      return configActions
    }
  }
}
