<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    :value="isOpened"
    v-on="$listeners"
    :max-width="width"
    @input="close"
  )
    template(
      #icon
      v-if="image"
    )
      v-img(
        :src="image"
        max-width="72px"
        max-height="72px"
        contain
      )
    template(#title)
      h2(
        class="text-center mt-8"
        v-if="title"
      ) {{ title }}
    template(#content)
      div(
        class="mt-8"
        v-if="!title"
      )
      v-row(class="mt-3 mb-3")
        v-col(
          cols="12"
          :class="textClass"
        )
          b(
            v-if="textPrefix"
            :class="textPrefixClass"
          ) {{ $t(textPrefix) }}
          | {{ $t(text) }}
    template(#actions)
      v-row(
        no-gutters
        justify="center"
      )
        v-col(cols="12")
          EButtonMain(
            @click="confirm"
            color="#00BBF9"
            width="100%"
            border-radius="16"
          )
            | {{ $t(buttonText) }}
</template>

<script>
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import Dialog from '~/models/system/Dialog'
import EButtonMain from '~/components/elements/buttons/e-button-main'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: {
    TOrmButtons,
    TDialog,
    EButtonMain,
    ESvgIcon
  },
  props: {
    type: {
      type: String,
      default: 'information'
    }
  },
  data: () => ({
    loading: false,
    keyLoading: null,
    keyData: null
  }),
  computed: {
    dialog() {
      return Dialog.query().where('type', this.type).first()
    },
    isOpened() {
      return this._.get(this.dialog, 'isOpened', false)
    },
    params() {
      return this._.get(this.dialog, 'params', '')
    },
    title() {
      return this._.get(this.params, 'title', '')
    },
    text() {
      return this._.get(this.params, 'text', '')
    },
    textPrefix() {
      return this._.get(this.params, 'textPrefix', '')
    },
    textPrefixClass() {
      return ['mr-1', this._.get(this.params, 'textPrefixClass', '')]
    },
    buttonText() {
      return this._.get(this.params, 'buttonText', this.$t('Next'))
    },
    width() {
      return this._.get(this.params, 'width', '800px')
    },
    textClass() {
      return this._.get(this.params, 'textClass', '')
    },
    onConfirm() {
      return this._.get(this.params, 'onConfirm', null)
    },
    image() {
      return this._.get(this.params, 'image', null)
    }
  },
  created() {
    if (!this.dialog) {
      Dialog.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    close() {
      this.dialog.close(false, true)
    },
    confirm() {
      if (this._.isFunction(this.onConfirm)) {
        this.onConfirm()
      }
      this.dialog.close(true, true)
    }
  }
}
</script>
