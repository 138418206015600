import { Model } from '@vuex-orm/core'

export default class DocumentSidebar extends Model {
  static entity = '_documentsidebar'

  static fields() {
    return {
      id: this.uid(),
      isOpened: this.attr(false),
      params: this.attr({})
    }
  }

  async toggle(params) {
    if (params.component !== this.params?.component) {
      await this.close()
    }
    this.isOpened = !this.isOpened
    this.params = params
    await this.$save()
  }

  async hide() {
    this.isOpened = false
    await this.$save()
  }

  async close() {
    this.isOpened = false
    this.params = null
    await this.$save()
  }
}
