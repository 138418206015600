<template lang="pug">
  div(class="mobile-filter")
    div(class="mobile-filter__body")
      template(v-for="(filter, idx) in filters")
        div(
          v-if="filter.items && filter.items.length"
          :key="idx"
          class="mobile-filter__section"
        )
          div(class="text-14px dark") {{ $t(filter.title) }}
          div(class="mobile-filter__section-items-wrapper")
            template(v-if="!filter.multiple")
              v-radio-group(
                v-model="selectedFilters[filter.model]"
                class="mt-0"
                dense
                hide-details
              )
                v-radio(
                  v-for="(item, idx) in filter.items"
                  :key="idx"
                  :label="item.text"
                  :value="item.value"
                )
            template(v-else)
              div(
                v-for="(item, idx) in filter.items"
                :key="idx"
                class="mobile-filter__item"
              )
                v-checkbox(
                  v-model="selectedFilters[filter.model]"
                  :value="item.value"
                  :label="item.text"
                  hide-details
                  :ripple="false"
                )
        div(
          v-if="filter.title === 'Period'"
          class="mobile-filter__section"
        )
          div(class="text-14px dark pb-3") {{ $t(filter.title) }}
          e-filters-datetime-range(
            v-model="selectedFilters.createdAt"
            type="date"
            :show-actions="false"
            :max="today"
            :clear-without-actions="clearPeriod"
            centered
            @resetClear="clearPeriod = $event"
          )
    div(class="mobile-filter__footer")
      v-btn(
        height="48"
        @click="resetAllFilters"
      ) {{ $t('Drop') }}
      v-btn(
        class="main-button"
        @click="applyFilters"
      ) {{ $t('Apply') }}
</template>

<script>
import EFiltersDatetimeRange from '~/modules/documents/components/elements/e-filters-datetime-range'
import Document from '~/modules/documents/models/Document'
import filters from '~/modules/documents/mixins/filters'
import dateTime from '~/mixins/datetime/dateTime'

export default {
  name: 'FormDocumentsFiltersMobile',
  components: { EFiltersDatetimeRange },
  mixins: [filters, dateTime],
  props: {
    closeDrawer: {
      type: Function,
      default: () => {}
    },
    searchValue: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      clearPeriod: false
    }
  },
  computed: {
    documentTypes() {
      return [
        {
          text: this.$t('All'),
          value: null
        },
        {
          text: this.$t('Inbox'),
          value: 'inbox'
        },
        {
          text: this.$t('Outbox'),
          value: 'outbox'
        }
      ]
    },
    filters() {
      return [
        {
          title: 'Type',
          model: 'folder',
          items: this.documentTypes,
          multiple: false
        },
        {
          title: 'Status',
          model: 'statuses',
          items: Document.statuses,
          multiple: true
        },
        {
          title: 'Period',
          model: 'createdAt'
        }
      ]
    }
  },
  watch: {
    searchValue(val) {
      this.selectedFilters.search = val
    }
  },
  methods: {
    applyFilters() {
      this.setFilters(this.selectedFilters)
      this.closeDrawer()
    },
    resetAllFilters() {
      this.resetFilters()
      this.clearPeriod = true
      this.closeDrawer()
    }
  }
}
</script>

<style scoped lang="scss">
.mobile-filter {
  &__body {
    padding-bottom: 80px;
  }

  &__section {
    padding: 16px 0 20px 0;

    &:not(:last-child) {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.08);
    }
  }

  &__footer {
    border-top: 0.5px solid rgba(0, 0, 0, 0.08);
    padding: 0 16px;
    background: #ffffff;
    height: 80px;

    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
    transform: translateZ(0);
  }
}
</style>
