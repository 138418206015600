import { get } from 'lodash'
import { filter } from 'lodash/collection'
import Document from '~/modules/documents/models/Document'
import routes from '~/const/routes'

export const state = () => ({
  // new
  items: [],
  meta: {
    totalItems: null
  },
  requestParams: null,
  documentScale: 1,
  shouldRerenderDocument: false,
  filters: {}
})

export const getters = {
  // new
  items: state => state.items,
  meta: state => state.meta,
  // old
  requestParams: state => state.requestParams,
  documentScale: state => state.documentScale,
  shouldRerenderDocument: state => state.shouldRerenderDocument,
  filters: state => state.filters
}

export const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
  },
  SET_META(state, meta) {
    state.meta = meta
  },
  SET_REQUEST_PARAMS(state, value) {
    state.requestParams = value
  },
  SET_DOCUMENT_SCALE(state, scale) {
    state.documentScale = scale
  },
  SET_SHOULD_RERENDER_DOCUMENT(state, value) {
    state.shouldRerenderDocument = value
  },
  SET_FILTERS(state, value) {
    state.filters = value
  },
  REMOVE_ITEM(state, itemId) {
    state.items = filter(state.items, item => item.id !== itemId)
  }
}

export const actions = {
  // new
  async getAll({ commit }, { config = {} }) {
    const { data, meta } = get(await this.$axios.get(routes.document.list(), config), 'data')

    commit('SET_ITEMS', data)
    commit('SET_META', meta)
    commit('SET_REQUEST_PARAMS', config.params)

    return { data, meta }
  },
  async delete({ commit }, { id, config = {} }) {
    await this.$axios.delete(routes.document.concrete(id), config)
    commit('REMOVE_ITEM', id)
  },
  // old
  async updateDocumentsWithSavedParams({ state }) {
    // todo: delete after rework to vuex based store
    const nonReadableVuexORMParams = {}
    if (state.requestParams['folder.id'] && state.requestParams['folder.id'] === '') {
      nonReadableVuexORMParams['folder.id'] = ''
    }
    return await Document.api()
      .filter(state.requestParams)
      .all(
        {},
        {
          params: {
            ...state.requestParams,
            ...nonReadableVuexORMParams
          }
        }
      )
  }
}
