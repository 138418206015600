<template lang="pug">
  div(class="profile-menu-content")
    div(class="profile-menu-content__icon")
      v-img(:src="require('~/assets/images/person.png')" max-width="16")
    div(class="profile-menu-content__title") {{ $t('Welcome') }}
    div(class="profile-menu-content__email") {{ $User.email }}
    div(class="px-3 w-100")
      e-organization-change(class="mb-4")
    v-divider(v-if="!xsDevice" class="w-100")
    v-list(dense class="w-100 py-0")
      v-list-item-group
        template(v-for="(item, idx) in menuItems")
          v-list-item(
            v-if="isVisible(item)"
            class="py-2"
            dense
            :key="idx"
            @click="item.call"
          )
            v-list-item-title(class="link__text") {{ item.text }}
    template(v-if="showAboutUs")
      v-divider(class="w-100")
      div(class="pt-3 px-3 pb-4 w-100")
        div(class="profile-menu-content__email mb-3 text-left w-100") {{ $t('About us') }}
        block-profile-socials
</template>

<script>
import EOrganizationChange from '~/modules/organizations/views/components/e-organization-change'
import auth from '~/modules/auth/auth'
import taxAuth from '~/mixins/pages/tax-auth'
import responsive from '~/mixins/pages/responsive'
import checkVisibility from '~/mixins/checkVisibility'
import BlockProfileSocials from '~/modules/profile/views/components/block-profile-socials'

export default {
  name: 'BlockProfileMenuContent',
  components: {
    EOrganizationChange,
    BlockProfileSocials
  },
  mixins: [taxAuth, responsive, checkVisibility],
  props: {
    showAboutUs: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    menuItems () {
      return [
        {
          text: this.$t('My account'),
          call: () => this.redirectTo('/profile')
        },
        {
          text: this.$t('My organizations'),
          call: () => this.redirectTo('/my-organizations')
        },
        {
          text: this.$t('Documents'),
          call: () => this.redirectTo('/documents'),
          visible: () => this.xsDevice
        },
        {
          text: this.$t('Archive'),
          call: () => this.redirectTo('/documents/archive'),
          visible: () => this.xsDevice
        },
        {
          text: this.$t('About us'),
          call: () => this.redirectTo('/about-us'),
          visible: () => this.xsDevice
        },
        {
          text: this.$t('Exit'),
          call: () => this.logout()
        }
      ]
    }
  },
  methods: {
    redirectTo (path) {
      this.$router.push(path)
      if (this.xsDevice) {
        this.$emit('closeDrawer')
      }
    },
    async logout () {
      await auth.logout(this.$store)
      this.destroyIitGlobalDialog()
      await this.$router.push({ name: 'auth' })
    }
  }
}
</script>

<style scoped lang="scss">
.profile-menu-content {
  padding-top: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__icon {
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background: rgba(0, 0, 0, 0.03);
  }
  &__title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }
  &__email {
    color: rgba(0, 0, 0, 0.50);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 16px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
</style>
