const pagination = {
  data: _ => ({
    options: {},
    prevOptions: null,
    prevFilters: null,
    links: {},
    meta: {},
    requestPromise: null,
    requestPromiseResolve: null
  }),
  computed: {
    totalRows () {
      // Fir when we create new rows we got totalItems from previous request.
      return this._.get(this.meta, 'totalItems', 0) || this.rows.length
    },
    $pagination () {
      if (this.model.paginated) {
        return {
          'server-items-length': this.totalRows
        }
      }
    },
    requestParams () {
      // groupBy, groupDesc, itemsPerPage, multiSort, mustSort, page, sortBy, sortDesc
      const { itemsPerPage, page, sortDesc } = this.options
      const sortBy = this.relatedSortBy()
      const filterBy = this.getServerFilters
      return { itemsPerPage, page, sortBy, sortDesc, filterBy }
    },
    pageStart () {
      if (this.options.page > 1) {
        return (this.options.page - 1) * this.meta.itemsPerPage + 1
      }
      return 1
    },
    pageStop () {
      return this.maxPage > this.options.page ? this.options.page * this.options.itemsPerPage : this.meta.totalItems
    },
    maxPage () {
      return Math.ceil(this.meta.totalItems / this.options.itemsPerPage)
    },
    disableNext () {
      return this.options.page >= this.maxPage
    },
    disablePrev () {
      return this.options.page <= 1
    },
    prevFiltersMap () {
      return new Map()
    }
  },
  async created () {
    const promises = []
    if (!this.model.paginated) {
      promises.push(this.requestData())
    }
    // TODO: uncomment if service needs prepend models
    // if (this.prepend && this.$Organization) {
    //   promises.push(this.prepend.load())
    // }

    await Promise.all(promises)
  },
  watch: {
    options: {
      async handler (val, prev) {
        const prevVal = this.prevOptions || prev
        this.prevOptions = val
        if (this.model.paginated) {
          const diff = this._.difference(Object.values(val), Object.values(prevVal))
          // For skipping first customRequest onload if filters are applied
          const isEmptyQuery = this._.isEmpty(this.$route.query)
          // Only on first page we know that 2 request can occur
          const pageHasNotChanged = this._.get(val, 'page') === 1 && this._.get(prevVal, 'page') === undefined
          if (!diff.length || (!isEmptyQuery && pageHasNotChanged)) {
            return
          }
          await this.requestData()
        }
      },
      deep: true
    },
    getServerFilters: {
      async handler (val, prev) {
        const prevVal = this.prevFilters || prev
        this.prevFilters = val
        let hasDiff = false
        const filtersWithoutSearch = { ...val } // search is being filtered by debouncedSearchString watcher
        delete filtersWithoutSearch.search
        for (const key in filtersWithoutSearch) {
          const prevValue = prevVal[key]
          const value = val[key]
          if (Array.isArray(value)) {
            const isPrevArr = Array.isArray(prevValue)
            if (!isPrevArr) {
              hasDiff = true
            } else if (value.length !== prevValue.length) {
              hasDiff = true
            } else if (value[0] !== prevValue[0] || value[1] !== prevValue[1]) { // createdAt case
              hasDiff = true
            }
          } else if (value !== prevValue) {
            hasDiff = true
          }
        }
        if (hasDiff) {
          await this.handleFiltersChange(val)
        }
      },
      deep: true
    },
    async debouncedSearchString (val) {
      await this.handleFiltersChange(val, true)
    }
  },
  methods: {
    prevPage () {
      if (this.disablePrev) {
        return
      }
      this.options = {
        ...this.options,
        page: this.options.page - 1
      }
    },
    nextPage () {
      if (this.disableNext) {
        return
      }
      this.options = {
        ...this.options,
        page: this.options.page + 1
      }
    },
    async handleFiltersChange (val, isSearch) {
      const hasPrevValue = key => !!this.prevFiltersMap.get(key)
      const filter = Object.entries(val).filter(([key, i]) => {
        if (this._.isNull(i) && !hasPrevValue(key)) {
          return false
        } else {
          this.prevFiltersMap.set(key, true)
        }
        if (Array.isArray(i) && !i.length) {
          if (hasPrevValue(key)) {
            this.prevFiltersMap.set(key, false)
          } else {
            return false
          }
        }
        if (Array.isArray(i) && i.length) {
          this.prevFiltersMap.set(key, true)
        }
        return true
      })

      if (this.model.paginated && (isSearch || filter.length)) {
        if (this.model.isForceFiltered && !this.isAsyncFiltersLoaded) { return }
        this.options.page = 1

        await this.requestData()
      }
      await this.$emit('filtersChange', val)
    },
    async requestData () {
      // TODO: handle admin cases
      // if (!this.$Organization && !this.$User?.isSuperAdmin) {
      //   return
      // }
      try {
        this.tableLoading = true

        if (this.requestPromise) {
          await this.requestPromise
        }

        this.requestPromise = new Promise((resolve) => {
          this.requestPromiseResolve = resolve
        })

        const route = (typeof this.requestRoute === 'function' && this.requestRoute()) || this.requestRoute
        let response
        if (this._.isFunction(this.customRequest)) {
          response = this._.get(await this.customRequest({
            model: this.model,
            requestParams: this.requestParams,
            route
          }), 'response')
        } else {
          response = this._.get(await this.model.api().all(this.requestParams, { route }), 'response')
        }
        this.links = this._.get(response, 'data.links', null)
        this.meta = this._.get(response, 'data.meta', null)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.tableLoading = false
        this.requestPromiseResolve()
        this.requestPromise = null
      }
    },
    relatedSortBy () {
      const { sortBy } = this.options
      const fields = this.model.getFields()

      return [].concat(sortBy).reduce((list, field) => {
        if (!field) { return list }

        const inter = this._.get(fields, field)
        const sortQuery = this._.get(this._.find(this.model.ormHeaders, { value: field }), 'sortQuery', null)

        if (!inter && !sortQuery) {
          if (this.$config.nodeENV === 'development') {
            // alert('Some model doesn\'t have some field by which we want sort!')
            // eslint-disable-next-line no-console
            console.error('Field: ', field)
            // eslint-disable-next-line no-console
            console.error('Model: ', this.model.name)
          }

          return list
        }

        if (sortQuery) {
          list.push(sortQuery)

          return list
        }

        let tmp

        if (inter.related || inter.parent) {
          tmp = {
            [field]: inter.related || inter.parent
          }
        } else if (this._.isFunction(inter.mutator)) {
          tmp = {
            [field]: inter.mutator()
          }
        }

        list.push(tmp || field)

        return list
      }, [])
    }
  }
}

export default pagination
