<template lang="pug">
  div(class="upload-contact")
    div(class="upload-contact__header")
      h2(class="title-32px mb-8 create-contact__title") {{ $t(title) }}
      div(class="section__content")
        e-input-document-upload(
          :file.sync="file"
          :uploaded-file="uploadedFileData"
          ref="fileInput"
          :externalText="$t('Uhe the \"{formats}\" formats or our template', { formats: 'CSV, XLSX, XLS' })"
          :limitedFormats="true"
        )
        div(class="form-actions")
          v-btn(
            height="48"
            @click="closeModal"
          ) {{ $t('Cancel') }}
          v-btn(
            class="main-button"
            @click="submit"
            :loading="isLoading"
          ) {{ $t('Import') }}
</template>

<script>
import Base64js from 'base64-js'
import { mapGetters } from 'vuex'
import responsive from '~/mixins/pages/responsive'
import EInputDocumentUpload from '~/modules/documents/components/elements/e-input-document-upload.vue'
import Contact from '~/modules/contacts/models/Contact'
import converters from '~/mixins/methods/converters'
import informationDialog from '~/mixins/dialogs/informationDialog'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  name: 'MUploadContact',
  components: {
    EInputDocumentUpload
  },
  mixins: [responsive, converters, informationDialog, contentDialog],
  props: {
    title: {
      type: String,
      default: ''
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      file: null,
      isLoading: false
    }
  },
  computed: {
    uploadedFileData() {
      return this.isEdit
        ? {
            name: this.editedDocument.title + '.' + this.editedDocument.fileExtension
          }
        : null
    },
    ...mapGetters('contacts', ['params'])
  },
  methods: {
    async submit() {
      const fileInputValid = await this.$refs.fileInput?.validate()
      if (!fileInputValid.valid) return

      try {
        this.isLoading = true
        const arrayBuffer = await this.blobToArrayBuffer(this.file)
        const uint8View = new Uint8Array(arrayBuffer)
        const payload = { importFileContent: Base64js.fromByteArray(uint8View) }
        const response = await Contact.api().import(payload)
        const isSuccess = this._.get(response, 'response.data.success', false)

        if (isSuccess) {
          this.informationDialog.open({
            title: this.$t('Contacts imported'),
            text: this.$t(
              this.$t(
                'All contacts have been imported into the system. Total number of contacts added: {count}',
                { count: this._.get(response, 'response.data.result.success', 0) }
              )
            ),
            textClass: 'text-center',
            width: '510px',
            buttonText: this.$t('Go to contacts'),
            image: require('~/assets/images/success3.png'),
            onConfirm: () => {
              Contact.api().filter(this.params).all()
            }
          })
          this.closeModal()
        } else {
          await this.contentDialog.open({
            component: 'm-create-contact-errors',
            width: '512px',
            componentProps: {
              title: this.$t('Contacts imported'),
              text: this.$t(
                this.$t(
                  'Successfully imported {successCount} contacts. {errorCount} contacts contained errors and were not added.',
                  {
                    successCount: this._.get(response, 'response.data.result.success', 0),
                    errorCount: this._.get(response, 'response.data.result.failed', 0)
                  }
                )
              ),
              errors: this._.get(response, 'response.data.errors', [])
            }
          })
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.isLoading = false
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.upload-contact__title {
  @media (max-width: map-get($breakpoints, 'sm')) {
    font-size: 24px !important;
    margin-bottom: 20px !important;
  }
}

.form-actions {
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  padding-top: 32px;
  > button {
    flex: 1;
  }
}
</style>
