const upload = {
  computed: {
    validationAccept() {
      if (this.limitedFormats) {
        return 'csv,xls,xlsx'
      }
      return 'jpg,jpeg,png,doc,docx,xls,xlsx,pdf,txt,p7s'
    },
    rules() {
      const rules = 'required|size:35000|ext:'
      return rules + this.validationAccept
    },
    extensionsMap() {
      if (this.limitedFormats) {
        return {
          xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          xls: 'application/vnd.ms-excel',
          csv: 'text/csv'
        }
      }
      return {
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        doc: 'application/msword',
        pdf: 'application/pdf',
        txt: 'text/plain',
        png: 'image/png',
        jpeg: 'image/jpeg',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        xls: 'application/vnd.ms-excel',
        p7s: 'application/pkcs7-signature'
      }
    },
    acceptedFileExtensions() {
      return Object.values(this.extensionsMap).join(',')
    }
  }
}

export default upload
