<template lang="pug">
  v-menu(
    :offset-x="offsetX"
    :offset-y="offsetY"
    left
    content-class="e-menu-tags"
    :close-on-content-click="false"
    @input="resetScroll"
  )
    template(v-slot:activator="{ on: onShowMenu }")
      template(v-if="customActivator")
        slot(name="customActivator"  :handleClick="onShowMenu.click" v-bind="$attrs"  class="e-menu-tags__button")
      v-tooltip(v-else-if="showTooltip" top)
        template(v-slot:activator="{ on: onShowTooltip }")
          v-btn(
            v-on="{ ...onShowTooltip, ...onShowMenu }"
            v-bind="$attrs"
            class="e-menu-tags__button"
          )
            e-svg-icon tag
            span(class="e-menu-tags__button-text")
              slot(name="buttonText")
        span {{ $t('Add tag') }}
      v-btn(
        v-else
        v-on="onShowMenu"
        v-bind="$attrs"
        class="e-menu-tags__button"
      )
        e-svg-icon(class="mr-2") tag
        span(class="e-menu-tags__button-text")
          slot(name="buttonText")
    v-list(max-height="430" class="e-menu-tags__list" ref="list")
      v-list-item(
        v-for="tag in tags" :key="tag.id"
        class="cursor-pointer menu-list__item"
        @click="handleAddTags(tag)"
      )
        v-list-item-title(class="e-menu-tags__title") {{ tag.name }}
        v-list-item-icon
          e-progress-circular(v-if="tagLoading[tag.id]" size="sm" width="3")
          e-svg-icon(v-else-if="documentTagIds.includes(tag.id)") check-circle-green
          div(v-else class="e-menu-tags__circle-wrapper")
            div(class="e-menu-tags__circle" :style="{ backgroundColor: tag.color }")
      v-list-item(
        class="cursor-pointer menu-list__item"
        @click="createTag({ document, documents })"
      )
        v-list-item-title(class="tag-title") {{ $t('New tag') }}
        v-list-item-icon
          e-svg-icon plus-black
</template>

<script>
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Tag from '~/modules/tags/models/Tag'
import tags from '~/modules/tags/mixins/tags'

export default {
  name: 'BlockMenuTags',
  components: {
    ESvgIcon,
    EProgressCircular
  },
  mixins: [tags],
  inheritAttrs: false,
  props: {
    massive: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object,
      default: null
    },
    documents: {
      type: Array,
      default: null
    },
    showTooltip: {
      type: Boolean,
      default: false
    },
    customActivator: {
      type: Boolean,
      default: false
    },
    offsetY: {
      type: Boolean,
      default: true
    },
    offsetX: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tagLoading: {}
    }
  },
  computed: {
    tags () {
      return Tag.all()
    },
    documentTagIds () {
      if (this.massive) {
        return []
      }
      return this._.map(this._.get(this.document, 'tags'), item => item.id)
    },
    documentIds () {
      if (!this.massive) {
        return []
      }
      return this._.map(this.documents, item => item.id)
    }
  },
  created () {
    for (const tag of this.tags) {
      this.$set(this.tagLoading, tag.id, false)
    }
  },
  methods: {
    handleAddTags (tag) {
      if (this.massive) {
        this.addTagToDocuments({ documentIds: this.documentIds, tagId: tag.id, tagName: tag.name, loading: this.tagLoading })
      } else {
        this.toggleDocumentTag({ id: this.document.id, tagId: tag.id, tagName: tag.name, isDelete: this.documentTagIds.includes(tag.id), loading: this.tagLoading })
      }
    },
    resetScroll () {
      const listEl = this.$refs.list?.$el || this.$refs.list
      if (listEl) {
        setTimeout(() => {
          listEl.scrollTop = 0
        }, 50)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.e-menu-tags {
  background: #fff;

  &__list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-width: 240px;
    width: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    scroll-behavior: smooth;
  }
  &__title {
    min-width: 150px;
    width: 100%;
  }
  &__circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &-wrapper {
      width: 20px;
      height: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__button {
    background: none !important;
  }
  &__button-text {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
}
</style>
