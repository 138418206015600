export const state = () => ({
  ghost: {}
})
export const getters = {
  ghost: state => state.ghost
}
export const mutations = {
  SET_GHOST(state, ghost) {
    state.ghost = ghost
  }
}
export const actions = {}
