// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/phone.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qr-code-promo[data-v-3265529b]{height:100%;padding:12px;position:relative;width:100%}.qr-code-promo-wrapper[data-v-3265529b]{background:#f8f2f7 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 100%;border-radius:12px;bottom:0;height:196px;left:0;position:absolute;width:184px}.qr-code-promo-wrapper.opened[data-v-3265529b]{background:#f8f2f7}.qr-code-promo-title[data-v-3265529b]{color:#121212;font-size:14px;font-weight:700;line-height:18px;position:relative}.qr-code-promo-text[data-v-3265529b],.qr-code-promo-title[data-v-3265529b]{font-family:\"mariupol-regular\",sans-serif}.qr-code-promo-text[data-v-3265529b]{color:#676767;font-size:12px;font-weight:500;line-height:16px;max-width:140px;padding-top:4px}.qr-code-promo-icon[data-v-3265529b]{cursor:pointer;height:16px;position:absolute;right:0;top:0;width:16px}.qr-code-promo-button[data-v-3265529b]{bottom:12px;left:12px;position:absolute}.qr-code-promo-code[data-v-3265529b]{padding-top:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
