<template lang="pug">
  e-button-upload-file(
    v-if="!$hasProVersion"
    ref="uploader"
    :validation-rules="rules"
    :accept="acceptedFileExtensions"
    :button-class="xsDevice ? 'mobile-fixed-button' : 'w-100 mb-6'"
    multiple
    :loading="loading"
    @onload="upload"
  )
    e-svg-icon(v-if="xsDevice") plus
    span(v-else) {{ $t('Download') }}
  v-btn(
    v-else
    class="main-button"
    :class="[xsDevice ? 'mobile-fixed-button' : 'w-100 mb-6']"
    @click="goToCreatePage"
  )
    e-svg-icon(v-if="xsDevice") plus
    span(v-else) {{ $t('Download') }}
</template>

<script>
import Base64js from 'base64-js'
import converters from '~/mixins/methods/converters'
import Document from '~/modules/documents/models/Document'
import EButtonUploadFile from '~/components/elements/buttons/e-button-upload-file'
import responsive from '~/mixins/pages/responsive'
import upload from '~/modules/documents/mixins/upload'

export default {
  name: 'EButtonDocumentUpload',
  components: {
    EButtonUploadFile
  },
  // TODO: resolve mixins
  mixins: [upload, converters, responsive],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async upload(files) {
      const { valid, errors } = await this.$refs.uploader.validate(files)
      if (!valid) {
        const errMessage = this._.get(errors, '[0]')
        this.$notification.error(errMessage)
        return
      }
      try {
        this.loading = true
        for (const file of files) {
          const arrayBuffer = await this.blobToArrayBuffer(file)
          const uint8View = new Uint8Array(arrayBuffer)
          const base64Str = Base64js.fromByteArray(uint8View)
          try {
            const payload = {
              file: base64Str,
              filename: file?.name,
              title: file?.name.split('.').slice(0, -1).join('.')
            }
            const documentId = this._.get(
              await Document.api().create(payload, true, {
                save: this.$route.name === 'documents'
              }),
              'response.data.id'
            )

            if (files && files.length === 1) {
              await this.$router.push({ path: `/documents/${documentId}` })
              window.dataLayer &&
                window.dataLayer.push({
                  event: 'document_downloaded',
                  email: this._.get(this.$User, 'email')
                })
              return
            }

            this.$notification.success(
              this.$t('{document} has been successfully uploaded', { document: file.name })
            )
          } catch (e) {
            const message = this._.get(e, 'response.data.message')
            this.$notification.error(
              this.$t('Error occurred with {document} - {error}', {
                document: file.name,
                error: message
              })
            )
          }
        }
        await this.$store.dispatch('documents/updateDocumentsWithSavedParams')
        window.dataLayer &&
          window.dataLayer.push({
            event: 'document_downloaded',
            email: this._.get(this.$User, 'email')
          })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    goToCreatePage() {
      this.$router.push({ name: 'documents-create' })
    }
  }
}
</script>

<style scoped lang="scss"></style>
