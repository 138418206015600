import { get } from 'lodash/object'
import { map, filter } from 'lodash/collection'
import routes from '~/const/routes'

export const state = () => ({
  items: [],
  meta: {
    currentPage: null,
    totalItems: null,
    itemsPerPage: null
  }
})

export const getters = {
  items: state => state.items,
  meta: state => state.meta
}

export const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
  },
  INSERT_ITEM(state, item) {
    state.items.push(item)
  },
  REMOVE_ITEM(state, itemId) {
    state.items = filter(state.items, item => item.id !== itemId)
  },
  UPDATE_ITEM_MESSAGE(state, { itemId, message }) {
    state.items = map(state.items, comment =>
      comment.id === itemId ? { ...comment, message } : comment
    )
  },
  ADD_ITEM_REPLIES(state, { itemId, reply }) {
    state.items = map(state.items, comment =>
      comment.id === itemId ? { ...comment, replies: [...comment.replies, reply] } : comment
    )
  },
  UPDATE_REPLY_MESSAGE(state, { itemId, replyId, message }) {
    state.items = map(state.items, comment =>
      comment.id === itemId
        ? {
            ...comment,
            replies: comment.replies.map(reply =>
              reply.id === replyId ? { ...reply, message } : reply
            )
          }
        : comment
    )
  },
  SET_META(state, meta) {
    state.meta = meta
  },
  RESET(state) {
    state.items = null
    state.meta = null
  }
}

export const actions = {
  async getCommentsByDocumentId({ commit }, { documentId, params }) {
    const { data, meta } = get(
      await this.$axios.get(routes.comment.documentComments(documentId), {
        params
      }),
      'data'
    )
    commit('SET_ITEMS', data)
    commit('SET_META', meta)

    return { data, meta }
  },
  async createComment({ commit }, { documentId, payload, selectedCommentId = null }) {
    const data = get(
      await this.$axios.post(routes.comment.documentComments(documentId), payload),
      'data'
    )

    if (selectedCommentId) {
      commit('ADD_ITEM_REPLIES', { itemId: selectedCommentId, reply: data })
    } else {
      commit('INSERT_ITEM', data)
    }

    return data
  },
  async updateComment({ commit }, { id, payload, replyId = null, parentId }) {
    const status = get(await this.$axios.put(routes.comment.concrete(id), payload), 'status')

    if (status === 200) {
      if (replyId) {
        commit('UPDATE_REPLY_MESSAGE', { itemId: parentId, replyId, message: payload.message })
      } else {
        commit('UPDATE_ITEM_MESSAGE', { itemId: id, message: payload.message })
      }
    }
  },
  async resolveComment({ commit }, { id }) {
    const resolved = get(await this.$axios.delete(routes.comment.concrete(id)), 'data.success')

    if (resolved) {
      commit('REMOVE_ITEM', id)
    }
  }
}
