import Base64js from 'base64-js'
import { mapGetters } from 'vuex'
import EMenuTags from '~/modules/tags/views/components/e-menu-tags'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import contentDialog from '~/mixins/dialogs/contentDialog'
import auth from '~/modules/auth/auth'
import User from '~/models/administration/User'
import mobileDrawer from '~/modules/mobile-drawer/mixins/mobileDrawer'
import DocumentSignatures from '~/modules/documents/models/DocumentSignatures'
import commonDocumentRequests from '~/modules/documents/mixins/request'
import informationSnackbar from '~/modules/snackbar/mixins/informationSnackbar'
import documentFieldsFormDialog from '~/modules/documents/mixins/documentFieldsFormDialog'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'
import successSnackbar from '~/modules/snackbar/mixins/successSnackbar'
import Participant from '~/modules/documents/models/Participant'
import documentSidebar from '~/modules/documents/mixins/documentSidebar'

const actions = {
  mixins: [
    commonDocumentRequests,
    mobileDrawer,
    documentFieldsFormDialog,
    edsIitCheckboxDialog,
    confirmationDialog,
    contentDialog,
    informationSnackbar,
    successSnackbar,
    documentSidebar
  ],
  computed: {
    ...mapGetters('templatefields', {
      filledFields: 'filledFields'
    }),
    ...mapGetters('organizations', {
      organizations: 'items',
      currentOrganization: 'currentOrganization'
    }),
    ...mapGetters('documents', {
      documentScale: 'documentScale'
    }),
    actions() {
      return [
        {
          name: 'sign',
          text: 'Sign',
          icon: 'pen',
          visible: document =>
            this._.get(document, 'currentUser.role') === Participant.ROLES.OWNER ||
            this._.get(document, 'currentUser.role') === Participant.ROLES.SIGNER,
          call: (document, isTableAction) => this.signDocument(document, isTableAction)
        },
        {
          name: 'share',
          text: 'Share',
          icon: 'file-send-1',
          visible: document => this._.get(document, 'canSend') && this.$User,
          call: (document, isTableAction) => this.shareDocument(document, isTableAction)
        },
        {
          name: 'convertToPdf',
          text: 'Convert to PDF',
          icon: 'convert',
          // TODO: move ['doc', 'docx'] to const!
          visible: document =>
            ['doc', 'docx'].includes(this._.get(document, 'fileExtension')) && this.$User,
          call: (document, isTableAction) => this.convertDocxToPdf(document, isTableAction)
        },
        {
          name: 'archive',
          text: 'Move to archive',
          icon: 'file-archive',
          visible: document => !this._.get(document, 'archived') && this.$User,
          call: (document, isTableAction) => this.archive(document, isTableAction)
        },
        {
          name: 'unarchive',
          text: 'Move to documents',
          icon: 'document',
          visible: document => this._.get(document, 'archived') && this.$User,
          call: (document, isTableAction) => this.unarchive(document, isTableAction)
        },
        {
          name: 'documents-quick-fill',
          text: 'Quick fill',
          icon: 'write',
          visible: document => this._.get(document, 'canFillFields') && !this.isWidget,
          call: document => this.fillFieldsQuick(document)
        },
        {
          name: 'download',
          text: 'Download',
          icon: 'document-download',
          call: document => this.downloadDocument(document)
        },
        {
          name: 'addtag',
          text: 'Add tag',
          icon: 'tag',
          component: EMenuTags,
          componentProps: document => ({
            document,
            customActivator: true,
            offsetX: true,
            offsetY: false
          })
        },
        {
          name: 'moveToFolder',
          text: 'Move to folder',
          icon: 'folder-arrow-right',
          visible: () => this.$hasProVersion,
          call: document => this.moveDocumentToFolder(document)
        },
        {
          name: 'delete',
          text: 'Delete',
          icon: 'danger-trash',
          visible: document => this._.get(document, 'canDelete') && this.$User,
          call: (document, isTableAction) => this.deleteDocument(document, isTableAction)
        }
      ]
    },
    secondaryActions() {
      const onlySecondaryActions = [
        {
          name: 'documentInfoToggle',
          text:
            this.documentSidebar?.params?.component === 'm-document-info' &&
            this.documentSidebar?.isOpened
              ? 'Hide sidebar'
              : 'Show sidebar',
          icon:
            this.documentSidebar?.params?.component === 'm-document-info' &&
            this.documentSidebar?.isOpened
              ? 'sidebar-left-opened'
              : 'sidebar-left',
          call: document => this.showDocumentInfo(document)
        },
        {
          name: 'commentsToggle',
          text: 'Show comments',
          icon: document =>
            document?.unresolvedCommentCount ? 'message-text-new' : 'message-text',
          call: document => this.showDocumentComments(document)
        }
      ]
      // TODO: change approach of grouping actions
      const secondaryActionNames = [
        'convertToPdf',
        'documents-quick-fill',
        'download',
        'archive',
        'unarchive',
        'delete'
      ]
      const secondaryActions = this._.filter(this.actions, action =>
        secondaryActionNames.includes(action.name)
      )
      return this._.concat(onlySecondaryActions, secondaryActions)
    },
    mobileActions() {
      const onlyMobileActions = [
        {
          name: 'rename',
          text: 'Rename',
          icon: 'pen-edit',
          visible: document => this._.get(document, 'currentUser.role') === Participant.ROLES.OWNER,
          call: document => this.rename(document)
        },
        {
          name: 'signatures',
          text: 'Signatures and access',
          icon: 'info-mark',
          call: document => this.showParticipants(document)
        }
      ]
      // TODO: change approach of grouping actions
      const secondaryActionNames = ['download', 'archive', 'unarchive', 'documents-quick-fill']
      const secondaryActions = this._.filter(this.actions, action =>
        secondaryActionNames.includes(action.name)
      )
      return this._.concat(onlyMobileActions, secondaryActions, this.additionalActions)
    },
    additionalActions() {
      return [
        {
          name: 'eventLog',
          text: this.$t('Event log'),
          icon: 'clock',
          visible: () => this.$User && this.$hasProVersion,
          call: document => this.showDocumentEventLog(document)
        }
      ]
    }
  },
  methods: {
    async shareDocument(document, isTableAction) {
      this.$gtm.push({
        event: 'click_share',
        email: this._.get(this.$User, 'email')
      })
      window.dataLayer &&
        window.dataLayer.push({
          event: 'click_share',
          email: this._.get(this.$User, 'email')
        })
      const hasSentInvites = await this.contentDialog.open({
        width: '724px',
        component: 'm-document-share',
        componentProps: {
          document
        }
      })
      if (isTableAction && hasSentInvites) {
        await this.model.api().read(document.id)
      }
    },
    async archive(document, isTableAction) {
      try {
        this.archiveLoading = true
        await this.model.api().toArchive(document.id)
        if (!isTableAction) {
          await this.model.api().read(document.id)
        } else {
          await this.customRequest({
            model: this.model,
            requestParams: this.requestParams
          })
        }
        this.$notification.success(this.$t('Document has successfully archived'))
        window.dataLayer &&
          window.dataLayer.push({
            event: 'document_archived',
            email: this._.get(this.$User, 'email')
          })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.archiveLoading = false
      }
    },
    async unarchive(document, isTableAction) {
      try {
        this.unarchiveLoading = true
        await this.model.api().fromArchive(document.id)
        if (!isTableAction) {
          await this.model.api().read(document.id)
        } else {
          await this.customRequest({
            model: this.model,
            requestParams: this.requestParams
          })
        }
        this.$notification.success(this.$t('Document has successfully unarchived'))
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.unarchiveLoading = false
      }
    },
    downloadDocument(document) {
      this.contentDialog.open({
        width: '512px',
        component: 'block-documents-download',
        componentProps: {
          documentId: document.id
        }
      })
    },
    async signDocument(document, isTableAction = false) {
      window.dataLayer &&
        window.dataLayer.push({
          event: 'click_sign',
          email: this._.get(this.$User, 'email')
        })
      return await this.useEdsIitCheckboxDialog({
        type: 'edsIitCheckboxCommon',
        method: 'open',
        keyCheck: false,
        pluginTitles: {
          options: this.$t('signPlugin.signDocument')
        },
        onConfirm: async ({ dialogClosed, edsKeyData, isDiia, sign }) => {
          if (dialogClosed) {
            return
          } else if (!edsKeyData?.keyData && !isDiia) {
            this.$handlers.error(
              'We could not read the key data. Please try again or choose a different key',
              this
            )
            return
          }
          try {
            const filesToSign = []
            const file = Base64js.toByteArray(this._.get(document, 'file', ''))
            let signedFile
            if (isDiia) {
              filesToSign.push({
                name: this._.get(document, 'title'),
                content: file,
                isHashed: true,
                externalId: this._.get(document, 'id')
              })
              signedFile = await sign(filesToSign, { keyCheck: false })
              if (!signedFile || !signedFile.length) {
                return
              }
            } else {
              signedFile = await sign(file, {
                keyCheck: false,
                title: 'Підпис файлу: ' + document.title
              })
              if (!signedFile) {
                return
              }
            }
            const organizationId = this._.get(document, 'currentUser.organization')
            await this.model.api().sign(
              document.id,
              {
                signature: isDiia ? this._.get(signedFile[0], 'signature') : signedFile
              },
              { headers: { 'X-Organization': organizationId || this.currentOrganization?.id } }
            )
            await this.model.api().read(document.id)
            if (!isTableAction) {
              await this.fetchSignatures(document.id)
              const showOnboardingPopup =
                this._.get(document, 'currentUser.role') === Participant.ROLES.SIGNER &&
                !this._.get(document, 'currentUser.registered') &&
                !auth.getAuthToken() &&
                !this.isWidget
              if (showOnboardingPopup) {
                this.callToRegister()
              }
              if (this.isWidget) {
                window.parent.postMessage('signed', '*')
              }
            } else {
              this.$notification.success(this.$t('Document has been signed successfully'))
            }
            if (!this.organizations?.length && !auth.getGuestAuthToken() && auth.getAuthToken()) {
              await this.$store.dispatch('organizations/getAll', { page: 1 })
              const firstOrganization = this._.get(this.organizations, '[0]')
              this.$store.commit('organizations/SET_CURRENT_ORGANIZATION', firstOrganization)
              await User.api().setCurrentOrganization({
                organizationId: this._.get(firstOrganization, 'id')
              })
            }
            window.dataLayer &&
              window.dataLayer.push({
                event: 'document_signed',
                email: this._.get(this.$User, 'email')
              })
            return true
          } catch (e) {
            this.$handlers.error(e, this)
            return false
          }
        }
      })
    },
    // TODO: refactor this
    async signDocumentWithDynamicFields(document) {
      try {
        const updatedDocument = await this.fillDynamicFields(document)
        await this.signDocument(updatedDocument)
        return true
      } catch (e) {
        this.$handlers.error(e, this)
        return false
      }
    },
    // TODO: do not need updatedDocument ?
    async shareDocumentWithDynamicFields(document) {
      try {
        const updatedDocument = await this.fillDynamicFields(document)
        await this.shareDocument(updatedDocument)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async deleteDocument(document, isTableAction) {
      await this.confirmationDialog.open({
        title: this.$t('Delete document'),
        text: this.$t('Document will be deleted. Are you sure you want to delete it?'),
        buttons: {
          approve: {
            text: this.$t('Delete')
          },
          dismiss: {
            text: this.$t('Cancel')
          }
        },
        icon: {
          src: require('~/assets/images/warning.png'),
          maxWidth: 80
        },
        onConfirm: async () => {
          // TODO: remove once table will be merged to all documents table (tags page, documents page)
          if (this.$route.name === 'documents-archive') {
            await this.$store.dispatch('documents/delete', {
              id: document.id
            })
          } else {
            await this.model.api().del(document)
          }
          await this.successSnackbar.open({
            text: this.$t('Document has been deleted successfully')
          })
          if (!isTableAction) {
            this.$router.push('/documents')
          }
        }
      })
    },
    rename(document) {
      this.mobileDrawer.open({
        title: 'Rename',
        component: 'form-document-rename',
        componentProps: {
          model: this.model,
          document
        }
      })
    },
    showParticipants(document) {
      const signatures = DocumentSignatures.all()
      this.mobileDrawer.open({
        title: 'Signatures and access',
        // todo: calculate height of drawer by component height
        height: '70%',
        background: '#F6F9FD',
        component: 'form-document-signatures',
        componentProps: {
          model: this.model,
          document,
          signatures
        }
      })
    },
    showShare(document) {
      this.mobileDrawer.open({
        title: 'Share this document',
        // todo: calculate height of drawer by component height
        height: '85%',
        component: 'm-document-share',
        componentProps: {
          document
        }
      })
    },
    // TODO: maybe move to other mixin
    async callToRegister() {
      await this.contentDialog.open({
        width: '512px',
        component: 'block-documents-success',
        componentProps: {
          title: this.$t('Document has been signed'),
          text: this.$t(
            'The sender will receive a message that you have signed the document. To start working with documents in the Dubidoc service, we recommend creating an account, it takes 30 seconds.'
          ),
          buttonText: this.$t('Create an account'),
          onSubmit: () => this.$router.push('/auth')
        }
      })
    },
    async fillDynamicFields(document) {
      try {
        const payload = {
          fields: this.filledFields,
          scale: this.documentScale
        }
        await this.model.api().fillDynamicFields(document.id, payload)
        this.$store.commit('templatefields/RESET_FILLED_FIELDS')
        const updatedDocument = this._.get(
          await this.model.api().read(document.id),
          'response.data'
        )
        const updatedFields = this._.get(updatedDocument, 'fields', [])
        this._.forEach(updatedFields, field => {
          this.$store.commit('templatefields/UPDATE_FIELD', {
            id: field.extraData.id,
            updatedData: {
              documentFieldValue: field.value,
              documentFieldPreValue: field.preValue
            }
          })
        })
        this.$store.commit('documents/SET_SHOULD_RERENDER_DOCUMENT', true)
        return updatedDocument
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async fillFieldsQuick(document) {
      const fields = this._.get(document, 'fields')
      const formattedFields = this._.map(fields, field => ({
        ...field.extraData,
        documentFieldId: field.id,
        documentFieldValue: field.value,
        documentFieldPreValue: field.preValue
      }))
      const filteredFields = this._.filter(formattedFields, field => !field.documentFieldValue)
      await this.documentFieldsFormDialog.open({
        fields: filteredFields
      })
    },
    async convertDocxToPdf(document) {
      try {
        await this.model.api().convertToPdf(document.id)
        await this.model.api().read(document.id)
        await this.informationSnackbar.open({
          icon: 'convert-white',
          text: this.$t('Document has converted to PDF')
        })
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    showDocumentInfo(document) {
      this.documentSidebar.toggle({
        component: 'm-document-info',
        componentProps: {
          document
        },
        onOpenRequest: async document => {
          await this.$store.dispatch('participants/getAllDocumentParticipants', {
            documentId: document.id
          })
        }
      })
    },
    async sendDocument(document) {
      try {
        this.sendLoading = true
        await this.model.api().send(document.id)
        await this.model.api().read(document.id)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.sendLoading = false
      }
    },
    moveDocumentToFolder(document) {
      this.contentDialog.open({
        component: 'm-folders-treeview',
        componentProps: {
          documents: [document] // TODO: possible change
        }
      })
    },
    showDocumentComments(document) {
      this.documentSidebar.toggle({
        component: 'm-document-comments',
        componentProps: {
          document
        },
        onOpenRequest: async document => {
          await this.$store.dispatch('comments/getCommentsByDocumentId', {
            documentId: document.id,
            params: {
              offset: 1,
              limit: 100,
              resolved: false
            }
          })
        }
      })
    },
    showDocumentEventLog(document) {
      this.documentSidebar.toggle({
        width: '280px',
        component: 'm-document-event-log',
        componentProps: {
          document
        }
      })
    }
  }
}

export default actions
