<template lang="pug">
  v-card(class="elevation-0 e-input-datetime__card")
    v-card-text(
      class="pa-0"
      :class="{ 'text-center': centered }"
    )
      v-date-picker(
        v-if="['date', 'datetime'].includes(type)"
        v-model="dateRange"
        :locale="$locale.code"
        color="primary"
        first-day-of-week="1"
        class="elevation-0 e-input-datetime"
        :min="min"
        :max="max"
        :title-date-format="titleDate"
        @input="dateChange"
        :key="datePickerKey"
        range
        scrollable
      )
        template(v-slot:default)
          div(
            v-if="fastDates"
            class="e-input-datetime__select"
          )
            v-select(
              v-if="fastDatesYearSelect"
              v-model="year"
              :items="years"
              :label="$t('Select year')"
              @change="selectYear"
              class="mb-1"
              hide-details
              outlined
            )
            v-select(
              v-model="fastRange"
              :items="fastRangeItems"
              @change="selectFastDate"
              :label="$t('Select period')"
              clearable
              hide-details
              outlined
            )
    v-card-actions(
      v-if="showActions"
      class="text-right"
    )
      v-col(
        cols="12"
        class="py-0"
      )
        v-row(justify="end")
          v-btn(
            color="primary"
            outlined
            @click="clear"
          ) {{ $t('clear') }}
          v-btn(
            color="primary"
            class="ml-2"
            @click="onChoose"
          ) {{ $t('choose') }}
</template>

<script>
import { formats } from '~/const/global'

export default {
  name: 'EFiltersDatetimeRange',
  props: {
    value: {
      type: Array,
      default: null
    },
    type: {
      type: String,
      default: 'date',
      validator: value => {
        return ['date', 'datetime', 'time'].includes(value)
      }
    },
    fastDates: {
      type: Boolean,
      default: false
    },
    fastDatesYearSelect: {
      type: Boolean,
      default: false
    },
    emmitOnlyOnSelectClicked: {
      type: Boolean,
      default: false
    },
    filterFastDates: {
      type: Function,
      default: null
    },
    chooseOnClose: {
      type: Boolean,
      default: false
    },
    defaultFastRange: {
      type: String,
      default: ''
    },
    showActions: {
      type: Boolean,
      default: true
    },
    centered: {
      type: Boolean,
      default: false
    },
    clearWithoutActions: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dateRange: [],
    fastRange: null,
    year: null,
    datePickerKey: 0
  }),
  computed: {
    min() {
      return this._.isFunction(this.$attrs.min) ? this.$attrs.min(this.dateRange) : this.$attrs.min
    },
    max() {
      return this._.isFunction(this.$attrs.max) ? this.$attrs.max(this.dateRange) : this.$attrs.max
    },
    years() {
      const date = new Date()
      const currentYear = date.getFullYear()
      const years = []
      for (let i = currentYear; i >= 2020; i--) {
        years.push(i)
      }
      return years
    },
    fastRangeKeys() {
      return {
        today: 'today',
        yesterday: 'yesterday',
        currentWeek: 'currentWeek',
        currentMonth: 'currentMonth',
        firstQuarter: 'firstQuarter',
        secondQuarter: 'secondQuarter',
        thirdQuarter: 'thirdQuarter',
        fourthQuarter: 'fourthQuarter',
        currentQuarter: 'currentQuarter',
        currentYear: 'currentYear'
      }
    },
    fastRangeItems() {
      const items = [
        {
          text: this.$t('Today'),
          value: this.fastRangeKeys.today
        },
        {
          text: this.$t('Yesterday'),
          value: this.fastRangeKeys.yesterday
        },
        {
          text: this.$t('Current week'),
          value: this.fastRangeKeys.currentWeek
        },
        {
          text: this.$t('Current month'),
          value: this.fastRangeKeys.currentMonth
        },
        {
          text: this.$t('_num quarter', { number: 1 }),
          value: this.fastRangeKeys.firstQuarter
        },
        {
          text: this.$t('_num quarter', { number: 2 }),
          value: this.fastRangeKeys.secondQuarter
        },
        {
          text: this.$t('_num quarter', { number: 3 }),
          value: this.fastRangeKeys.thirdQuarter
        },
        {
          text: this.$t('_num quarter', { number: 4 }),
          value: this.fastRangeKeys.fourthQuarter
        },
        {
          text: this.$t('Current quarter'),
          value: this.fastRangeKeys.currentQuarter
        },
        {
          text: this.$t('Current year'),
          value: this.fastRangeKeys.currentYear
        }
      ]
      return this._.isFunction(this.filterFastDates) ? this.filterFastDates(items) : items
    }
  },
  watch: {
    dateRange(val) {
      if (!this.showActions) {
        let value = val
        if (this._.get(val, '[0]') === this._.get(val, '[1]')) {
          value = [this._.get(val, '[0]')]
        }
        if (typeof value[0] === 'undefined') {
          return
        }
        this.$emit('input', value)
      }
    },
    clearWithoutActions(val) {
      if (val) {
        this.clear()
      }
    }
  },
  created() {
    this.setCurrentYear()
    if (Array.isArray(this.value)) {
      this.dateRange = this.value
    }
    if (
      this.defaultFastRange &&
      Object.values(this.fastRangeKeys).includes(this.defaultFastRange)
    ) {
      this.fastRange = this.defaultFastRange
    }
  },
  methods: {
    setCurrentYear() {
      const date = new Date()
      this.year = date.getFullYear()
    },
    selectYear() {
      this.selectFastDate(this.fastRange)
    },
    selectFastDate(val) {
      let dateStart = new Date()
      let dateEnd = new Date()

      dateStart.setFullYear(this.year)
      dateEnd.setFullYear(this.year)

      if (val === this.fastRangeKeys.yesterday) {
        dateStart.setDate(dateStart.getDate() - 1)
        dateEnd.setDate(dateEnd.getDate() - 1)
      } else if (val === this.fastRangeKeys.currentWeek) {
        dateStart = this.$moment(dateStart).startOf('isoWeek').toDate()
        dateEnd = this.$moment(dateEnd).endOf('isoWeek').toDate()
      } else if (val === this.fastRangeKeys.currentMonth) {
        dateStart = this.$moment(dateStart).startOf('month').toDate()
        dateEnd = this.$moment(dateEnd).endOf('month').toDate()
      } else if (val === this.fastRangeKeys.firstQuarter) {
        dateStart.setMonth(0)
        dateStart.setDate(1)
        dateEnd.setMonth(2)
        dateEnd.setDate(31)
      } else if (val === this.fastRangeKeys.secondQuarter) {
        dateStart.setMonth(3)
        dateStart.setDate(1)
        dateEnd.setMonth(5)
        dateEnd.setDate(30)
      } else if (val === this.fastRangeKeys.thirdQuarter) {
        dateStart.setMonth(6)
        dateStart.setDate(1)
        dateEnd.setMonth(8)
        dateEnd.setDate(30)
      } else if (val === this.fastRangeKeys.fourthQuarter) {
        dateStart.setMonth(9)
        dateStart.setDate(1)
        dateEnd.setMonth(11)
        dateEnd.setDate(31)
      } else if (val === this.fastRangeKeys.currentQuarter) {
        const currentMonth = dateStart.getMonth()
        if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2) {
          return this.selectFastDate(this.fastRangeKeys.firstQuarter)
        } else if (currentMonth === 3 || currentMonth === 4 || currentMonth === 5) {
          return this.selectFastDate(this.fastRangeKeys.secondQuarter)
        } else if (currentMonth === 6 || currentMonth === 7 || currentMonth === 8) {
          return this.selectFastDate(this.fastRangeKeys.thirdQuarter)
        } else {
          return this.selectFastDate(this.fastRangeKeys.fourthQuarter)
        }
      } else if (val === this.fastRangeKeys.currentYear) {
        dateStart.setMonth(0)
        dateStart.setDate(1)
        dateEnd.setMonth(11)
        dateEnd.setDate(31)
      }

      if (this.max) {
        if (new Date(dateEnd) > new Date(this.max)) {
          dateEnd = this.max
        }
        if (new Date(dateStart) > new Date(this.max)) {
          dateStart = this.max
        }
      }

      if (this.min) {
        if (new Date(dateEnd) < new Date(this.min)) {
          dateEnd = this.min
        }
        if (new Date(dateStart) < new Date(this.min)) {
          dateStart = this.min
        }
      }

      this.dateRange = [
        this.$moment(dateStart).format(formats.dateISO8601),
        this.$moment(dateEnd).format(formats.dateISO8601)
      ]
      this.datePickerKey += 1
    },
    dateChange(val) {
      this.fastRange = null
      if (!this.emmitOnlyOnSelectClicked) {
        this.$emit('input', val)
      }
    },
    now() {
      return new this.$moment()
    },
    onChoose() {
      // this need because there are some issue when select date range in v-date-picker
      if (this.dateRange.length === 2) {
        if (this.$moment(this.dateRange[0]).isAfter(this.dateRange[1])) {
          this.dateRange.reverse()
        }
      }
      this.$emit('input', this.dateRange)
      this.$emit('close')
    },
    // TODO: add CLEAR BTN
    clear() {
      this.dateRange = []
      this.$emit('input', this.dateRange)
      this.$emit('close')
      if (!this.showActions) {
        this.$emit('resetClear', false)
      }
    },
    titleDate(dates) {
      if (!dates || !dates.length) {
        return '-'
      } else if (dates.length === 1) {
        return this.$moment(dates[0]).format(formats.date)
      } else if (dates.length === 2) {
        const sortedDates = dates.concat().sort()
        return (
          this.$moment(sortedDates[0]).format(formats.date) +
          ' - ' +
          this.$moment(sortedDates[1]).format(formats.date)
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.e-input-datetime {
  &::v-deep table {
    tr {
      background: none !important;
    }

    th {
      text-align: center;
      border-bottom: 0 !important;
      padding: 0 !important;
    }

    td {
      border-radius: 0 !important;
      width: auto;
      height: auto;
      padding: 0 !important;
    }
  }

  &__card {
    height: 100%;
    .v-picker {
      border-radius: 0 !important;

      &__title {
        height: auto;
      }

      .v-date-picker-title__date {
        font-size: 1.25rem;
      }
    }
  }

  &__select {
    max-width: 280px;
    display: block;
    margin: 0 auto;
  }
}
</style>
