<template lang="pug">
  v-menu(
    v-model="menu"
    offset-y
    :close-on-content-click="false"
    :nudge-left="menuLeftOffset"
    :nudge-bottom="menuBottomOffset"
    style="z-index: 102 !important"
  )
    template(v-slot:activator="{ on }")
      slot(
        name="activator"
        :listeners="on"
      )
        div
          div(
            v-if="imageDataURL"
            class="e-input-signature__image"
            v-on="on"
          )
            img(:src="imageDataURL")
          v-text-field(
            v-else
            v-bind="$attrs"
            :class="$attrs.classes"
            v-on="on"
            @focus.prevent
            readonly
          )
            template(v-slot:append)
              e-svg-icon pen-gray
    div(
      class="e-input-signature__menu"
      :style="menuStyles"
    )
      div(class="text-16px fw-700 dark pb-4") {{ $t('Electronic ink signature') }}
      div(class="e-input-signature__canvas-container")
        canvas(
          ref="signatureCanvas"
          v-bind="canvasSettings"
        )
      div(class="text-12px py-4") {{ $t('By signing this document electronically, I agree that my signature and initials are the equivalent of my handwritten signature and are considered originals on all documents, including legally binding contracts.') }}
      div(class="e-input-signature__actions")
        v-btn(
          class="e-input-signature__actions-btn"
          height="40"
          @click="clear"
        ) {{ $t(clearButtonText) }}
        v-btn(
          class="main-button sm e-input-signature__actions-btn"
          @click="save"
        ) {{ $t(saveButtonText) }}
</template>

<script>
import SignaturePad from 'signature_pad'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

const MENU_WIDTH = 460
const MENU_HEIGHT = 200
const MENU_PADDING = 16

export default {
  name: 'EInputSignature',
  components: {
    ESvgIcon
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: null
    },
    saveButtonText: {
      type: String,
      default: 'Add and sign'
    },
    clearButtonText: {
      type: String,
      default: 'Refresh field'
    },
    menuLeftOffset: {
      type: [Number, String],
      default: 0
    },
    menuBottomOffset: {
      type: [Number, String],
      default: 50
    }
  },
  data() {
    return {
      menu: false,
      signatureController: null,
      imageDataURL: null
    }
  },
  computed: {
    menuStyles() {
      return {
        maxWidth: MENU_WIDTH + 'px',
        padding: MENU_PADDING + 'px'
      }
    },
    canvasSettings() {
      return {
        width: MENU_WIDTH - MENU_PADDING * 2 + 'px',
        height: MENU_HEIGHT + 'px'
      }
    }
  },
  watch: {
    menu(val) {
      if (val) {
        this.$nextTick().then(() => {
          if (!this.signatureController) {
            this.signatureController = new SignaturePad(this.$refs.signatureCanvas)
          }
          if (this.imageDataURL && this.signatureController) {
            if (this.signatureController.isEmpty()) {
              this.signatureController.fromDataURL(this.imageDataURL)
            }
          }
        })
      }
    },
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.setSignatureImage(val)
        } else {
          this.imageDataURL = null
          if (this.signatureController) {
            this.signatureController.clear()
          }
        }
      }
    }
  },
  methods: {
    setSignatureImage(base64str) {
      const prefix = 'data:image/png;base64,'
      if (!base64str.includes(prefix)) {
        this.imageDataURL = prefix + base64str
      } else {
        this.imageDataURL = base64str
      }
    },
    clear() {
      this.imageDataURL = null
      this.signatureController.clear()
      this.$emit('input', null)
    },
    save() {
      if (!this.signatureController.isEmpty()) {
        this.imageDataURL = this.signatureController.toDataURL()
        const base64String = this.imageDataURL.split(',')[1]
        this.$emit('input', base64String)
      } else {
        this.imageDataURL = null
        this.$emit('input', null)
      }
      this.menu = false
    }
  }
}
</script>

<style scoped lang="scss">
.e-input-signature {
  &__menu {
    max-height: 400px;
    width: 100%;
    height: 100%;

    position: relative;

    border-radius: 16px;
    background: $white;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);

    @media (max-width: 600px) {
      max-height: unset;
    }
  }

  &__image {
    width: 100%;
    height: 96px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #fff;
    border-radius: 12px;
    border: 1px solid rgba(3, 1, 2, 0.64);

    position: relative;

    img {
      max-width: 100%;
      height: 100%;
    }
  }
  &__image-remove {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  &__canvas-container {
    background-color: $background-gray;
  }
  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
  &__actions-btn {
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}
</style>
