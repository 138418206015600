import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'

export default class Comment extends OrmModel {
  static entity = 'comment'

  static fields () {
    return {
      id: this.attr(null),
      user: this.attr(null),
      message: this.attr(null),
      replies: this.attr(null),
      createdAt: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getCommentsByDocumentId = function (documentId) {
        return this.get(Model.$routes.comment.documentComments(documentId), {
          save: true,
          persistBy: 'create'
        })
      }
      return configActions
    }
  }
}
