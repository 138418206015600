import { get } from 'lodash/object'
import { find, map, filter } from 'lodash/collection'
import routes from '~/const/routes'

export const state = () => ({
  items: []
})

export const getters = {
  items: state => state.items
}

export const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
  },
  ADD_ITEM(state, item) {
    const isUpdate = find(state.items, participant => participant.id === item.id)
    if (isUpdate) {
      state.items = map(state.items, participant =>
        participant.id === item.id ? item : participant
      )
    } else {
      state.items.push(item)
    }
  },
  REMOVE_ITEM(state, itemId) {
    state.items = filter(state.items, item => item.id !== itemId)
  },
  RESET(state) {
    state.items = null
  }
}

export const actions = {
  async getAllDocumentParticipants({ commit }, { documentId, config = {} }) {
    const participants = get(
      await this.$axios.get(routes.document.participants(documentId), config),
      'data'
    )
    commit('SET_ITEMS', participants)

    return participants
  },
  async createDocumentParticipant({ commit }, { documentId, payload, config = {} }) {
    const participant = get(
      await this.$axios.post(routes.document.participants(documentId), payload, config),
      'data'
    )
    commit('ADD_ITEM', participant)
    return participant
  },
  async update({ commit }, { id, payload, config = {} }) {
    const updatedParticipant = get(
      await this.$axios.put(routes.participant.concrete(id), payload, config),
      'data'
    )
    commit('ADD_ITEM', updatedParticipant)
    return updatedParticipant
  },
  async delete({ commit }, { id, config = {} }) {
    await this.$axios.delete(routes.participant.concrete(id), config)
    commit('REMOVE_ITEM', id)
  }
}
