import Dialog from '~/models/system/Dialog'

const contentDialogContentTop = {
  computed: {
    contentDialogContentTop() {
      return Dialog.query().where('type', 'content_top').first()
    }
  }
}
export default contentDialogContentTop
