<template lang="pug">
  div(class="documents-signed-success")
    div(class="documents-signed-success__image")
      v-img(:src="require('~/assets/images/check-green.png')" max-width="80")
    div(class="documents-signed-success__content")
      h3(class="documents-signed-success__title") {{ title }}
      p(class="documents-signed-success__text") {{ text }}
    div(class="documents-signed-success__actions" :class="{ 'solo': isInformationDialog }")
      template(v-if="isInformationDialog")
        v-btn(class="main-button w-100" @click="closeModal(true)") {{ $t('Ok') }}
      template(v-else)
        v-btn(@click="closeModal(false)" height="48") {{ $t('Skip') }}
        v-btn(class="main-button" @click="submit") {{ buttonText }}
</template>

<script>
export default {
  name: 'BlockDocumentsSuccess',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    buttonText: {
      type: String,
      default: null
    },
    onSubmit: {
      type: Function,
      default: null
    },
    isInformationDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    submit () {
      if (typeof this.onSubmit === 'function') {
        this.onSubmit()
      }
      this.closeModal()
    }
  }
}
</script>

<style scoped lang="scss">
.documents-signed-success {
  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  &__content {
    text-align: left;
  }
  &__title {
    margin-bottom: 12px;

    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    color: $black;
  }
  &__text {
    margin-bottom: 32px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
  }
  &__actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }

    &.solo {
      grid-template-rows: 1fr;
      gap: 0;

      @media (min-width: map-get($breakpoints, 'md')) {
        grid-template-columns: 1fr;
        gap: 0;
        grid-template-rows: none;
      }
    }
  }
}
</style>
