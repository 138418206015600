<template lang="pug">
  div
    h2(
      class="title-32px mb-2"
      :class="{ 'mb-8': isEdit }"
    ) {{ title }}
    p(
      v-if="!isEdit"
      class="text-14px mb-8"
    ) {{ $t('Use folders for structure documents') }}
    v-form(@submit.prevent.stop="submit")
      ValidationObserver(ref="form")
        ValidationProvider(
          :name="wrapQuotesProviderName($t('Folder name'))"
          v-slot="{ errors }"
          vid="name"
          rules="required|min:3"
        )
          v-text-field(
            v-model.trim="name"
            filled
            :label="$t('Folder name')"
            :error-messages="errors"
          )
            template(v-slot:append)
              e-svg-icon folder-outline-gray
        div(class="two-buttons-wrapper")
          v-btn(
            height="48"
            @click="closeModal"
          ) {{ $t('Cancel') }}
          v-btn(
            class="main-button"
            :loading="loading"
            type="submit"
          ) {{ buttonText }}
</template>

<script>
import { mapGetters } from 'vuex'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import validate from '~/mixins/validation/validate'
import successSnackbar from '~/modules/snackbar/mixins/successSnackbar'
import commonDocumentRequests from '~/modules/documents/mixins/request'

export default {
  name: 'FormFolders',
  components: { ESvgIcon },
  mixins: [commonDocumentRequests, validate, successSnackbar],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    type: {
      type: String,
      validator: value => ['create', 'edit'].includes(value),
      default: 'create'
    },
    item: {
      type: Object,
      default: null
    },
    parentId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      name: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters('folders', {
      currentFolder: 'currentFolder'
    }),
    isEdit() {
      return this.type === 'edit'
    },
    title() {
      return this.isEdit ? this.$t('Rename') : this.$t('New folder')
    },
    buttonText() {
      return this.isEdit ? this.$t('Save') : this.$t('Create')
    },
    snackbarSuccessText() {
      return this.isEdit
        ? this.$t('Folder has renamed')
        : this.$t('Folder “{folderName}” created', { folderName: this.name })
    }
  },
  created() {
    if (this.item) {
      this.name = this.item.name
    }
  },
  methods: {
    async submit() {
      try {
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return false
        }
        this.loading = true
        if (this.isEdit) {
          await this.$store.dispatch('folders/update', {
            id: this.item.id,
            payload: { name: this.name, parentId: this.currentFolder?.id }
          })
        } else {
          const payload = {
            name: this.name,
            scope: 'archive',
            parentId: this.parentId ?? this.currentFolder?.id
          }
          await this.$store.dispatch('folders/create', {
            payload
          })
        }
        this.closeModal()
        await this.successSnackbar.open({
          text: this.snackbarSuccessText
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
