<template lang="pug">
  div(class="create-contact")
    h2(class="title-32px mb-2") {{ $t(title) }}
    div(class="create-contact__content")
      div(class="create-contact__tab") {{ $t('Information') }}
      div(class="create-contact__details")
        div(class="create-contact__item")
          div(class="create-contact__icon")
            e-svg-icon(name="user")
          div(class="create-contact__text")
            div(class="create-contact__label") {{ $t('Name') }}
            div(class="create-contact__value") {{ contact.name }}
        div(class="create-contact__item create-contact__item--small")
          div(class="create-contact__item")
            div(class="create-contact__icon")
              e-svg-icon(name="sms-2")
            div(class="create-contact__text")
              div(class="create-contact__label") {{ $t('Email') }}
              div(class="create-contact__value") {{ contact.email }}
          div(class="create-contact__item")
            div(class="create-contact__icon")
              e-svg-icon(name="bank")
            div(class="create-contact__text")
              div(class="create-contact__label") {{ $t('EDRPOU') }}
              div(class="create-contact__value") {{ contact.edrpou }}

      div(class="create-contact__actions")
        v-btn(
          class="secondary-button"
          @click="editModalOpen"
        ) {{ $t('Edit') }}
</template>

<script>
import EButtonMain from '~/components/elements/buttons/e-button-main'
import EInputWrapper from '~/components/elements/inputs/e-input-wrapper'
import EInputDynamic from '~/components/elements/inputs/e-input-dynamic'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  name: 'MCreateContact',
  components: {
    EButtonMain,
    EInputWrapper,
    EInputDynamic
  },
  mixins: [contentDialog],
  props: {
    title: {
      type: String,
      default: ''
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    update: {
      type: Function,
      default: () => {}
    },
    contact: {
      type: Object,
      default: null
    }
  },
  methods: {
    async editModalOpen() {
      await this.closeModal
      await this.contentDialog.open({
        component: 'm-edit-contact',
        width: '640px',
        componentProps: {
          title: 'Edit contact',
          contact: this.contact
        }
      })
    }
  }
}
</script>

<style lang="scss">
.create-contact {
  min-height: 320px;
  height: 100%;

  &__content {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__tab {
    border-radius: 32px;
    background: #8f257d;
    width: max-content;
    padding: 8px 16px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
  }
  &__details {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    flex-grow: 1;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &--small {
      margin-bottom: 32px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;
    }
  }
  &__icon {
    width: 44px;
    height: 44px;
    margin-right: 12px;
    border-radius: 50%;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      width: 20px;
      height: 20px;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 4px;
    color: #676767;
  }
  &__value {
    font-size: 14px;
    font-weight: 500;
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 16px;
    margin-top: auto;
    > button {
      flex: 1;
    }
  }
}
</style>
