import { get } from 'lodash'
import Cookies from 'js-cookie'
import Document from '~/modules/documents/models/Document'
import DocumentSignatures from '~/modules/documents/models/DocumentSignatures'
import AccessToken from '~/modules/profile/models/AccessToken'
import Tag from '~/modules/tags/models/Tag'
import AuthToken from '~/modules/auth/models/AuthToken'
import CurrentUser from '~/modules/auth/models/CurrentUser'
import Organization from '~/modules/organizations/models/Organization'
import OrganizationUsers from '~/modules/organizations/models/OrganizationUsers'
import OrganizationInvite from '~/modules/organizations/models/OrganizationInvite'
import OrganizationSearch from '~/models/directories/search/OrganizationSearch'
import EdsIitCheckbox from '~/modules/eds-iit-checkbox/models/EdsIitCheckbox'
import Template from '~/modules/templates/models/Template'

export default {
  getCode(login) {
    return AuthToken.api().getCode(login)
  },
  authenticate({ login, code }, referralLink) {
    return AuthToken.api().getToken(login.toLowerCase(), code, referralLink)
  },
  loginById(userId) {
    return AuthToken.api().getTokenForUser(userId)
  },

  /**
   * This used for login by other user from super admin user
   */
  async loginAsOtherUser(userId) {
    if (!this.getAuthToken()) {
      return
    }
    const superAdminToken = this.getAuthToken()
    await this.loginById(userId)
    superAdminToken.userType = AuthToken.userTypes.SUPER_ADMIN
    await superAdminToken.$save()
    await Promise.all([CurrentUser.deleteAll()])
    // await superAdminToken.
  },

  /**
   * This used for super admin user
   */
  async applyAdminToken() {
    if (this.getSuperAdminAuthToken()) {
      const adminToken = this.getSuperAdminAuthToken()
      await this.logout()
      adminToken.userType = AuthToken.userTypes.USER
      await adminToken.$save()
    }
  },

  logout(vuexStore) {
    Cookies.remove('authorized', { domain: '.dubidoc.com.ua', secure: true, sameSite: 'None' })
    if (vuexStore) {
      vuexStore.dispatch('resetStores')
    }
    return Promise.all([...this.deleteEntities(), AuthToken.deleteAll()])
  },

  refreshToken() {
    return AuthToken.api().refresh(this.getRefreshToken())
  },

  getAuthToken() {
    return AuthToken.query().where('userType', AuthToken.userTypes.USER).first()
  },

  getGuestAuthToken() {
    return AuthToken.query().where('userType', AuthToken.userTypes.GUEST).first()
  },

  getSuperAdminAuthToken() {
    return AuthToken.query().where('userType', AuthToken.userTypes.SUPER_ADMIN).first()
  },

  getToken() {
    const authToken = this.getAuthToken()
    return get(authToken, 'token', null)
  },

  getBearer() {
    const token = this.getToken()
    return token ? 'Bearer ' + token : null
  },

  getRefreshToken() {
    const authToken = this.getAuthToken()
    return get(authToken, 'refresh_token', null)
  },

  isAuthenticated() {
    return !!this.getAuthToken()
  },

  deleteCommonEntities() {
    return [OrganizationSearch.deleteAll(), EdsIitCheckbox.deleteData()]
  },

  deleteEntities() {
    return [
      ...this.deleteCommonEntities(),
      CurrentUser.deleteAll(),
      Organization.deleteAll(),
      Tag.deleteAll(),
      AccessToken.deleteAll(),
      OrganizationUsers.deleteAll(),
      OrganizationInvite.deleteAll(),
      Document.deleteAll(),
      DocumentSignatures.deleteAll(),
      Template.deleteAll()
    ]
  }
}
